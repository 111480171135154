import { createAction, props } from '@ngrx/store';

// GET ORDERS
export const GET_DAILY_ORDERS_REPORTS = '[Reports] Get Daily Orders Reports';
export const GET_WEEKLY_ORDERS_REPORTS = '[Reports] Get Weekly Orders Reports';
export const GET_MONTHLY_ORDERS_REPORTS = '[Reports] Get Monthly Orders Reports';

// SET ORDERS
export const SET_DAILY_ORDERS_REPORTS = '[Reports] Set Daily Orders Reports';
export const SET_MONTHLY_ORDERS_REPORTS = '[Reports] Set Monthly Orders Reports';
export const SET_WEEKLY_ORDERS_REPORTS = '[Reports] Set Weekly Orders Reports';

// GET DELIVERIES
export const GET_DAILY_DELIVERIES_REPORTS = '[Reports] Get Daily Deliveries Reports';
export const GET_WEEKLY_DELIVERIES_REPORTS = '[Reports] Get Weekly Deliveries Reports';
export const GET_MONTHLY_DELIVERIES_REPORTS = '[Reports] Get Monthly Deliveries Reports';

// SET DELIVERIES
export const SET_DAILY_DELIVERIES_REPORTS = '[Reports] Set Daily Deliveries Reports';
export const SET_MONTHLY_DELIVERIES_REPORTS = '[Reports] Set Monthly Deliveries Reports';
export const SET_WEEKLY_DELIVERIES_REPORTS = '[Reports] Set Weekly Deliveries Reports';

// GET PAYMENTS
export const GET_DAILY_PAYMENTS_REPORTS = '[Reports] Get Daily Payments Reports';
export const GET_WEEKLY_PAYMENTS_REPORTS = '[Reports] Get Weekly Payments Reports';
export const GET_MONTHLY_PAYMENTS_REPORTS = '[Reports] Get Monthly Payments Reports';

// SET PAYMENTS
export const SET_DAILY_PAYMENTS_REPORTS = '[Reports] Set Daily Payments Reports';
export const SET_MONTHLY_PAYMENTS_REPORTS = '[Reports] Set Monthly Payments Reports';
export const SET_WEEKLY_PAYMENTS_REPORTS = '[Reports] Set Weekly Payments Reports';

// GET SALES
export const GET_DAILY_SALES_REPORTS = '[Reports] Get Daily Sales Reports';
export const GET_WEEKLY_SALES_REPORTS = '[Reports] Get Weekly Sales Reports';
export const GET_MONTHLY_SALES_REPORTS = '[Reports] Get Monthly Sales Reports';

// SET SALES
export const SET_DAILY_SALES_REPORTS = '[Reports] Set Daily Sales Reports';
export const SET_MONTHLY_SALES_REPORTS = '[Reports] Set Monthly Sales Reports';
export const SET_WEEKLY_SALES_REPORTS = '[Reports] Set Weekly Sales Reports';

export const SET_SELECTED_DELIVERY_FITLER = '[Reports] Set Selected Delivery Filter';

export const SET_SELECTED_SALES_FITLER = '[Reports] Set Selected Sales Filter';

export const RESET_REPORTS = '[Structure] Reset Reports';

export const ERROR = '[Reports] Error';

// GET ORDERS
export const getDailyOrdersReports = createAction(
  GET_DAILY_ORDERS_REPORTS,
  props<{ year: string; month: string; node: string }>()
);
export const getWeeklyOrdersReports = createAction(GET_WEEKLY_ORDERS_REPORTS, props<{ year: string; node: string }>());
export const getMonthlyOrdersReports = createAction(
  GET_MONTHLY_ORDERS_REPORTS,
  props<{ year: string; node: string }>()
);

// SET ORDERS
export const setDailyOrdersReports = createAction(SET_DAILY_ORDERS_REPORTS, props<{ daily: any }>());
export const setMonthlyOrdersReports = createAction(SET_MONTHLY_ORDERS_REPORTS, props<{ monthly: any }>());
export const setWeeklyOrdersReports = createAction(SET_WEEKLY_ORDERS_REPORTS, props<{ weekly: any }>());

// GET Deliveries
export const getDailyDeliveriesReports = createAction(
  GET_DAILY_DELIVERIES_REPORTS,
  props<{ year: string; month: string; node: string }>()
);
export const getWeeklyDeliveriesReports = createAction(
  GET_WEEKLY_DELIVERIES_REPORTS,
  props<{ year: string; node: string }>()
);
export const getMonthlyDeliveriesReports = createAction(
  GET_MONTHLY_DELIVERIES_REPORTS,
  props<{ year: string; node: string }>()
);

// SET Deliveries
export const setDailyDeliveriesReports = createAction(SET_DAILY_DELIVERIES_REPORTS, props<{ daily: any }>());
export const setMonthlyDeliveriesReports = createAction(SET_MONTHLY_DELIVERIES_REPORTS, props<{ monthly: any }>());
export const setWeeklyDeliveriesReports = createAction(SET_WEEKLY_DELIVERIES_REPORTS, props<{ weekly: any }>());

// GET Payments
export const getDailyPaymentsReports = createAction(
  GET_DAILY_PAYMENTS_REPORTS,
  props<{ year: string; month: string; node: string }>()
);
export const getWeeklyPaymentsReports = createAction(
  GET_WEEKLY_PAYMENTS_REPORTS,
  props<{ year: string; node: string }>()
);
export const getMonthlyPaymentsReports = createAction(
  GET_MONTHLY_PAYMENTS_REPORTS,
  props<{ year: string; node: string }>()
);

// SET Payments
export const setDailyPaymentsReports = createAction(SET_DAILY_PAYMENTS_REPORTS, props<{ daily: any }>());
export const setMonthlyPaymentsReports = createAction(SET_MONTHLY_PAYMENTS_REPORTS, props<{ monthly: any }>());
export const setWeeklyPaymentsReports = createAction(SET_WEEKLY_PAYMENTS_REPORTS, props<{ weekly: any }>());

// GET Sales
export const getDailySalesReports = createAction(
  GET_DAILY_SALES_REPORTS,
  props<{ year: string; month: string; node: string }>()
);
export const getWeeklySalesReports = createAction(GET_WEEKLY_SALES_REPORTS, props<{ year: string; node: string }>());
export const getMonthlySalesReports = createAction(GET_MONTHLY_SALES_REPORTS, props<{ year: string; node: string }>());

// SET Sales
export const setDailySalesReports = createAction(SET_DAILY_SALES_REPORTS, props<{ daily: any }>());
export const setMonthlySalesReports = createAction(SET_MONTHLY_SALES_REPORTS, props<{ monthly: any }>());
export const setWeeklySalesReports = createAction(SET_WEEKLY_SALES_REPORTS, props<{ weekly: any }>());

export const setSelectedDeliveryFilter = createAction(
  SET_SELECTED_DELIVERY_FITLER,
  props<{ selectedDeliveryFilter: string }>()
);

export const setSelectedSalesFilter = createAction(SET_SELECTED_SALES_FITLER, props<{ selectedSalesFilter: string }>());

export const reset = createAction(RESET_REPORTS);

export const error = createAction(ERROR, props<{ error: string }>());
