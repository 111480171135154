import { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import * as firebase from 'firebase/app';
import * as moment from 'moment-timezone';
import { object, list } from 'rxfire/database';
import { map } from 'rxjs/operators';
import { from, of } from 'rxjs';

// Services
import * as Time from 'src/app/core/utils/time';

// Models & Constants
import { RTDB_CONSTANTS } from 'src/app/core/constants/rtdb.constants';
import { Branches } from 'src/app/models/data/branch.model';
import { Merchant } from 'src/app/models/data/merchant.model';
import { OrderDetails } from 'src/app/models/data/order.model';
import { DeliveryDetails } from 'src/app/models/integration/delivery.model';

// State
import { Store } from '@ngrx/store';
import { StoreRootState } from 'src/app/state/state.reducers';
import * as fromAuth from 'src/app/state/auth/auth.selector';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root',
})
export class RTDBService {
  private db = firebase.database();
  private MERCHANTS = RTDB_CONSTANTS.MERCHANTS;
  private merchantUid: string;
  private branches: Branches;
  private hqUid: string;

  constructor(private store: Store<StoreRootState>) {
    this.store.select(fromAuth.selectUser).subscribe((user: Merchant) => {
      if (user) {
        this.merchantUid = user.role === 'owner' ? user?.uid : user?.ownerId;
        this.branches = user?.branches;
        this.hqUid = user?.hq;
      }
    });
  }

  getBranchRef(branchUid: string) {
    const uid = branchUid ? branchUid : this.hqUid;
    return `${this.MERCHANTS.ROOT}/${this.merchantUid}/${this.MERCHANTS.BRANCHES}/${uid}`;
  }

  getBranchOrdersRef(branchUid: string) {
    const uid = branchUid ? branchUid : this.hqUid;
    return `${this.MERCHANTS.ROOT}/${this.merchantUid}/${this.MERCHANTS.BRANCHES}/${uid}/${this.MERCHANTS.ORDERS}`;
  }

  getBranchOrderRef(branchUid: string, orderDetails: OrderDetails) {
    const branchOrdersRef = this.getBranchOrdersRef(branchUid);
    const todayObj = Time.getDateinTimeZone(orderDetails.acceptedAt, orderDetails?.timezone);
    const date = todayObj.date();
    const month = todayObj.month() + 1;
    const year = todayObj.year();
    return `${branchOrdersRef}/${year}/${month}/${date}/${orderDetails.id}`;
  }

  getBranchAggregationRef(branchUid: string) {
    return `${this.getBranchRef(branchUid)}/${this.MERCHANTS.AGGREGATION}`;
  }

  getBranchOrderAggregationRef(branchUid: string) {
    return `${this.getBranchRef(branchUid)}/${this.MERCHANTS.AGGREGATION}/${this.MERCHANTS.ORDERS}`;
  }

  getBranchPriorityAggregationRef(branchUid: string) {
    return `${this.getBranchRef(branchUid)}/${this.MERCHANTS.AGGREGATION}/${this.MERCHANTS.MANAGEMENT}/${
      this.MERCHANTS.PRIORITIES
    }`;
  }

  getBranchPaymentAggregationRef(branchUid: string) {
    return `${this.getBranchRef(branchUid)}/${this.MERCHANTS.AGGREGATION}/${this.MERCHANTS.PAYMENTS}`;
  }

  getBranchSalesAggregationRef(branchUid: string) {
    return `${this.getBranchRef(branchUid)}/${this.MERCHANTS.AGGREGATION}/${this.MERCHANTS.SALES}`;
  }

  getBranchDeliveryAggregationRef(branchUid: string) {
    return `${this.getBranchRef(branchUid)}/${this.MERCHANTS.AGGREGATION}/${this.MERCHANTS.DELIVERIES}`;
  }

  listenToBranchOrderAggregation(selectedBranch: string, dateFilter: string) {
    const branchOrderUrl = this.getBranchOrderAggregationRef(selectedBranch);
    const branchRef = this.db.ref(`${branchOrderUrl}/${this.MERCHANTS.DAILY}`).child(dateFilter);
    return object(branchRef).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  listenToBranchPriorityAggregation(selectedBranch: string, dateFilter: string) {
    const branchOrderUrl = this.getBranchPriorityAggregationRef(selectedBranch);
    const branchRef = this.db.ref(`${branchOrderUrl}/${this.MERCHANTS.DAILY}`).child(dateFilter);
    return object(branchRef).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  listenToBranchDeliveryAggregation(selectedBranch: string, dateFilter: string) {
    const branchOrderUrl = this.getBranchDeliveryAggregationRef(selectedBranch);
    const branchRef = this.db.ref(`${branchOrderUrl}/${this.MERCHANTS.DAILY}`).child(dateFilter);
    return object(branchRef).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  listenToRealTimeOrders(selectedBranch: string, dateFilter: string) {
    const branchOrdersUrl = this.getBranchOrdersRef(selectedBranch);
    const branchOrdersRef = this.db.ref(branchOrdersUrl).child(dateFilter).orderByChild('time');
    return list(branchOrdersRef).pipe(
      map((changes) =>
        changes.map((c) => {
          return { id: c.snapshot.key, event: c.event, ...c.snapshot.val() };
        })
      )
    );
  }

  updateOrderDetails({ branchUid, orderDetails, deliveryOrder }) {
    const loc = this.getBranchOrderRef(branchUid, orderDetails);
    const deliveryDetails: DeliveryDetails = {
      status: 'assigned',
      courier: deliveryOrder?.courier,
      courierOrderId: deliveryOrder?.order?.order_id,
      deliveryFee: deliveryOrder?.deliveryFee,
      updateAt: moment().toISOString(),
    };
    return from(this.updateData(loc, deliveryDetails));
  }

  updateOrderPriority(branchUid, order, priorityLevel) {
    const loc = this.getBranchOrderRef(branchUid, order);
    const priority = {
      priority: priorityLevel,
    };
    return from(this.updateData(loc, priority));
  }

  removeOrder({ branchUid, orderDetails }) {
    const loc = this.getBranchOrderRef(branchUid, orderDetails);
    const removedStatus = {
      status: 'removed',
      updateAt: moment().toISOString(),
    };
    return from(this.updateData(loc, removedStatus));
  }

  getDailyReports(year: string, month: string, node: string) {
    const dailyReports = this.db.ref(`${this.MERCHANTS.ROOT}/${this.merchantUid}/agg/${node}/d/${year}/${month}`);
    return object(dailyReports).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  getMonthlyReports(year: string, node: string) {
    const monthlyReports = this.db.ref(`${this.MERCHANTS.ROOT}/${this.merchantUid}/agg/${node}/m/${year}`);
    return object(monthlyReports).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  getWeeklyReports(year: string, node: string) {
    const weeklyReports = this.db.ref(`${this.MERCHANTS.ROOT}/${this.merchantUid}/agg/${node}/w/${year}`);
    return object(weeklyReports).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  getOrdersPriority() {
    const monthlyPriority = this.db.ref(
      `${this.MERCHANTS.ROOT}/${this.merchantUid}/${this.MERCHANTS.AGGREGATION}/${this.MERCHANTS.MANAGEMENT}/${this.MERCHANTS.PRIORITIES}/${this.MERCHANTS.DAILY}/2020`
    );
    return object(monthlyPriority).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  getDailyPriorities(year: string, month: string) {
    const dailyPriorities = this.db.ref(
      `${this.MERCHANTS.ROOT}/${this.merchantUid}/agg/management/priorities/d/${year}/${month}`
    );
    return object(dailyPriorities).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  getMonthlyPriorities(year: string) {
    const monthlyPriorities = this.db.ref(
      `${this.MERCHANTS.ROOT}/${this.merchantUid}/agg/management/priorities/m/${year}`
    );
    return object(monthlyPriorities).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  getWeeklyPriorities(year: string) {
    const monthlyPriorities = this.db.ref(
      `${this.MERCHANTS.ROOT}/${this.merchantUid}/agg/management/priorities/w/${year}`
    );
    return object(monthlyPriorities).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  getCustomerOrder(taskId: string) {
    // const customerOrder = this.db.ref(`customers/${cid}/orders/${oid}`);
    const customerOrder = this.db.ref(`tracking/${taskId}`);
    return object(customerOrder).pipe(map((change) => ({ ...change.snapshot.val() })));
  }

  setData(loc: string, obj: object) {
    return this.db.ref(loc).set(obj);
  }

  removeData(loc: string) {
    return this.db.ref(loc).remove();
  }

  updateData(loc: string, obj: object) {
    return this.db.ref(loc).update(obj);
  }
}
