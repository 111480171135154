import { Action, createReducer, on } from '@ngrx/store';
import * as SettingsActions from './settings.actions';

export interface State {
  structure: object[];
  integration: object[];
  feature: object[];
}

export const initialState: State = {
  structure: null,
  integration: null,
  feature: null,
};

const settingsReducer = createReducer(
  initialState,
  on(SettingsActions.getIntegrationSettings, (state) => state),
  on(SettingsActions.getFeatureSettings, (state) => state),
  on(SettingsActions.reset, () => ({ ...initialState })),
  on(SettingsActions.error, (state) => state)
);

export function reducer(state: State | undefined, action: Action) {
  return settingsReducer(state, action);
}
