import {
  RealTimeOrders,
  RealTimeOrder,
  OrdersByDates,
  OrdersDetails,
  OrderDetails,
} from 'src/app/models/data/order.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as OperationActions from './operation.actions';
import { Aggregation } from 'src/app/models/aggregation/aggregation.model';
import * as Time from 'src/app/core/utils/time';

export interface State {
  // Real Time Orders
  realTimeOrders: RealTimeOrders;
  // Cached Orders
  ordersByDates: OrdersByDates;
  ordersDetails: OrdersDetails;
  // Selected Order
  selectedRealTimeOrder: RealTimeOrder;
  selectedOrderDetails: OrderDetails;
  // Filter
  selectedDateFilter: string; // YYYY/M/D
  // SelectedBranch
  selectedBranch: string;
  // Error
  error: Error;
}

export const initialState: State = {
  realTimeOrders: {
    loading: true,
    orders: [],
  },
  selectedRealTimeOrder: {
    loading: true,
  },
  selectedOrderDetails: {
    loading: true,
  },
  selectedDateFilter: Time.moment$().format(Time.defaultFormat),
  selectedBranch: '',
  ordersByDates: {
    loading: true,
    orders: {},
  },
  ordersDetails: {
    loading: true,
    orders: {},
  },
  error: null,
};

const operationReducer = createReducer(
  initialState,
  // Real Time Orders
  on(OperationActions.getRealTimeOrders, (state) => state),
  on(OperationActions.setRealTimeOrders, (state, { orders }) => ({
    ...state,
    realTimeOrders: {
      orders,
      loading: false,
    },
    error: null,
  })),
  on(OperationActions.initializeRealTimeOrders, (state) => ({
    ...state,
    error: null,
    realTimeOrders: {
      loading: true,
      orders: [],
    },
  })),

  // Orders Details
  on(OperationActions.getOrderDetails, (state) => state),
  on(OperationActions.setOrderDetails, (state, { orderDetails }) => ({
    ...state,
    error: null,
    ordersDetails: {
      orders: {
        ...state.ordersDetails.orders,
        [orderDetails.id]: {
          ...orderDetails,
          loading: false,
        },
      },
    },
  })),

  // Selected Order
  on(OperationActions.setSelectedRealTimeOrder, (state, { selectedRealTimeOrder }) => ({
    ...state,
    error: null,
    selectedRealTimeOrder: {
      ...selectedRealTimeOrder,
      loading: false,
    },
  })),
  on(OperationActions.setSelectedOrderDetails, (state, { selectedOrderDetails }) => ({
    ...state,
    error: null,
    selectedOrderDetails: {
      ...selectedOrderDetails,
      loading: false,
    },
  })),
  on(OperationActions.initializeSelectedOrderDetails, (state) => ({
    ...state,
    error: null,
    selectedOrderDetails: {
      loading: true,
    },
  })),

  // Date Filter
  on(OperationActions.setSelectedDateFilter, (state, { selectedDateFilter }) => ({
    ...state,
    error: null,
    selectedDateFilter,
  })),

  // Selected Branch
  on(OperationActions.setSelectedBranch, (state, { selectedBranch }) => ({
    ...state,
    error: null,
    selectedBranch,
  })),

  // Reset
  on(OperationActions.reset, () => ({ ...initialState })),

  // Errors
  on(OperationActions.error, (state, { error }) => ({
    ...state,
    error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return operationReducer(state, action);
}
