import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';

import { REGION } from 'src/app/core/types/region.type';
import { INTEGRATION } from 'src/app/core/constants/integration.constants';

export interface CloudFunctionsCallableAPI {
  [key: string]: firebase.functions.HttpsCallable;
}

@Injectable({
  providedIn: 'root',
})
export class CloudFunctionsService {
  region: REGION = 'asia-northeast1';
  functions = firebase.app().functions(this.region);

  get(delivery: string): CloudFunctionsCallableAPI {
    const METHODS = INTEGRATION.DELIVERY[delivery];
    return {
      getQuote: this.functions.httpsCallable(METHODS.GET_QUOTE),
      createOrder: this.functions.httpsCallable(METHODS.CREATE_ORDER),
      cancelOrder: this.functions.httpsCallable(METHODS.CANCEL_ORDER),
      getCourier: this.functions.httpsCallable(METHODS.GET_COURIER),
    };
  }

  paymongo(): CloudFunctionsCallableAPI {
    return {
      createPaymentMethod: this.functions.httpsCallable(INTEGRATION.PAYMONGO.CREATE_PAYMENT_METHOD),
    };
  }
}
