import { MrSpeedyQuoteResponse } from './../../../models/integration/mrspeedy.model';
import { Injectable } from '@angular/core';

import { INTEGRATION } from 'src/app/core/constants/integration.constants';
import { MrSpeedyQuote, MrSpeedyOrder } from 'src/app/models/integration/mrspeedy.model';
import { OrderDetails } from 'src/app/models/data/order.model';
import { CloudFunctionsService } from 'src/app/services/firebase/cloud.functions.services';

@Injectable({
  providedIn: 'root',
})
export class MrspeedyService {
  private mrspeedyFunctions = this.cloudFunction.get(INTEGRATION.COURIER.MRSPEEDY);

  constructor(private cloudFunction: CloudFunctionsService) {}

  async assign(order: OrderDetails, merchantOwnerUid: string) {
    const data: MrSpeedyOrder = {
      matter: order.type,
      is_client_notification_enabled: true,
      is_contact_person_notification_enabled: true,
      vehicle_type_id: 8, // 7 — Car, 8 — Motorbike
      points: [
        {
          address: order.pickup.address.location,
          contact_person: {
            phone: order.pickup.customer.mobile,
            name: order.pickup.customer.name,
          },
        },
        {
          address: order.delivery.address.location,
          contact_person: {
            phone: order.delivery.customer.mobile,
            name: order.delivery.customer.name,
          },
        },
      ],
    };
    return this.mrspeedyFunctions.createOrder({ data, merchantOwnerUid });
  }

  async quote(order: OrderDetails, merchantOwnerUid: string) {
    const data: MrSpeedyQuote = {
      matter: order.matter,
      points: [
        {
          address: order?.pickup?.address?.location,
          contact_person: {
            phone: order?.pickup?.customer?.mobile,
          },
        },
        {
          address: order?.delivery?.address?.location,
          contact_person: {
            phone: order?.delivery?.customer?.mobile,
          },
        },
      ],
    };
    return this.mrspeedyFunctions
      .getQuote({ data, merchantOwnerUid })
      .then((res) => {
        console.log('response', res);
        const response = res?.data?.data as MrSpeedyQuoteResponse;
        response['loading'] = false;
        response['price'] = response?.order?.payment_amount;
        return response;
      })
      .catch((err) => {
        console.log('error', err);
        const response = err;
        response['loading'] = true;
        response['price'] = 0;
        return response;
      });
  }

  async cancel(order_id: string, gfzOrderId: string, merchantOwnerUid: string, branchUid: string) {
    return this.mrspeedyFunctions.cancelOrder({
      gfzOrderId,
      order_id,
      merchantOwnerUid,
      branchUid,
    });
  }

  async getCourier(id: string, merchantOwnerUid: string) {
    return this.mrspeedyFunctions.getCourier({ order_id: id, merchantOwnerUid });
  }
}
