import { Action, createAction, props } from '@ngrx/store';
import { Branch } from 'src/app/models/data/branch.model';
import { User } from './auth.model';

export const GET_USER = '[Auth] Get User';
export const SET_SELECTED_BRANCH = '[Auth] Set Selected Branch';
export const AUTHENTICATED = '[Auth] Authenticated';
export const NOT_AUTHENTICATED = '[Auth] Not Authenticated';

export const REGISTER = '[Auth] Email Register';
export const EMAIL_LOGIN = '[Auth] Email Login';
export const CONNECT_GOOGLE = '[Auth] Connect Google';
export const CONNECT_FACEBOOK = '[Auth] Connect Facebook';
export const RESET_PASSWORD = '[Auth] Reset Password';
export const LOGOUT = '[Auth] Logout';

export const ERROR = '[Auth] Error';

export const getUser = createAction(GET_USER);
export const setSelectedBranch = createAction(SET_SELECTED_BRANCH, props<{ selectedBranch: Branch }>());
export const register = createAction(REGISTER, props<{ email: string; password: string }>());
export const emailLogin = createAction(EMAIL_LOGIN, props<{ email: string; password: string }>());
export const connectGoogle = createAction(CONNECT_GOOGLE);
export const connectFacebook = createAction(CONNECT_FACEBOOK);
export const resetPassword = createAction(RESET_PASSWORD, props<{ email: string }>());
export const logout = createAction(LOGOUT);
export const authenticated = createAction(AUTHENTICATED, props<{ user: User }>());
export const notAuthenticated = createAction(NOT_AUTHENTICATED);
export const error = createAction(ERROR, props<{ error: Error }>());
