import { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import * as firebase from 'firebase/app';
import { docData } from 'rxfire/firestore';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

// Services
import * as Time from 'src/app/core/utils/time';

// Models & Constants
import { RTDB_CONSTANTS } from 'src/app/core/constants/rtdb.constants';
import { Branches } from 'src/app/models/data/branch.model';
import { Merchant } from 'src/app/models/data/merchant.model';

// State
import { Store } from '@ngrx/store';
import { StoreRootState } from '../../state/state.reducers';
import * as AuthActions from 'src/app/state/auth/auth.actions';
import * as fromAuth from 'src/app/state/auth/auth.selector';
import { Rating } from 'src/app/models/v2/rating.model';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  private fs = firebase.firestore();
  private MERCHANTS = RTDB_CONSTANTS.MERCHANTS;
  private merchantUid: string;
  private branches: Branches;
  private hqUid: string;

  constructor(private store: Store<StoreRootState>) {
    this.store.select(fromAuth.selectUser).subscribe((user: Merchant) => {
      if (user) {
        this.merchantUid = user.role === 'owner' ? user?.uid : user?.ownerId;
        this.branches = user?.branches;
        this.hqUid = user?.hq;
        const selectedBranch = this.branches[this.hqUid];
        this.store.dispatch(AuthActions.setSelectedBranch({ selectedBranch }));
      }
    });
  }

  getOrderPath(branchUid: string, orderId: string) {
    return `${this.MERCHANTS.ROOT}/${this.merchantUid}/${this.MERCHANTS.BRANCHES}/${branchUid}/${this.MERCHANTS.ORDERS}/${orderId}`;
  }

  getDeliveryPath(branchUid: string, deliveryId: string) {
    return `${this.MERCHANTS.ROOT}/${this.merchantUid}/${this.MERCHANTS.BRANCHES}/${branchUid}/${this.MERCHANTS.DELIVERIES}/${deliveryId}`;
  }

  getOrderDetails(orderId: string, branchUid: string) {
    const branchUid$ = branchUid ? branchUid : this.hqUid;
    const path = this.getOrderPath(branchUid$, orderId);
    const ref = this.fs.doc(path);
    return docData(ref);
  }

  setDeliveryDetails(details: any, deliveryId: string, branchUid: string) {
    const branchUid$ = branchUid ? branchUid : this.hqUid;
    const path = this.getDeliveryPath(branchUid$, deliveryId);
    const ref = this.fs.doc(path);
    ref.set(details);
    return docData(ref);
  }
  setRating(rating: Rating) {
    const ref = this.fs.collection('tasks').doc(rating.orderId);
    ref.set(rating, { merge: true });
    return docData(ref);
  }

  getRating(taskId: string) {
    const ref = this.fs.collection('tasks').doc(taskId);
    ref.get();
    return docData(ref);
  }

  updateOrderDetails(details: any, orderId: string, branchUid: string) {
    const branchUid$ = branchUid ? branchUid : this.hqUid;
    const path = this.getOrderPath(branchUid$, orderId);
    const ref = this.fs.doc(path);
    ref.update(details);
    return docData(ref);
  }
}
