export const TASK_CONSTANT = {
  unassigned: 'orgs/zip/tasks/unassigned/',
};
export const MERCHANT_CONSTANT = {
  newOrder: 'mer-tmf/new/',
  inProgressOrder: 'mer-tmf/inprogress/',
  finishedOrder: 'mer-tmf/finished/',
};

const NEW = 'new';
const INPROGRESS = 'inprogress';
const FINISHED = 'finished';
const CANCELLED = 'cancelled';
const REMOVED = 'removed';

const MERCHANTS = {
  ROOT: 'merchants',
  BRANCHES: 'branches',
  ORDER: 'order',
  ORDERS: 'orders',
  MANAGEMENT: 'management',
  PRIORITIES: 'priorities',
  SALES: 'sales',
  PAYMENTS: 'payments',
  DELIVERY: 'delivery',
  DELIVERIES: 'deliveries',
  AGGREGATION: 'agg',
  DAILY: 'd',
  WEEKLY: 'w',
  MONTHLY: 'm',
  YEARLY: 'y',
  NEW: 'new',
  INPROGRESS: 'inprogress',
  FINISHED: 'finished',
  REMOVED: 'removed',
  GLORIAFOODID: 'gfz',
};

const STATUS = {
  UNASSIGNED: 'unassigned',
  ASSIGNED: 'assigned',
  ACKNOWLEDGE: 'acknowledge',
  STARTED: 'started',
  INPROGRESS: 'inprogress',
  SUCCESSFUL: 'successful',
  FAILED: 'failed',
  DECLINED: 'declined',
  CANCELLED: 'cancelled',
  REMOVED: 'removed',
};

const STATUS_MAP = {
  unassigned: NEW,
  assigned: INPROGRESS,
  acknowledge: INPROGRESS,
  started: INPROGRESS,
  inprogress: INPROGRESS,
  successful: FINISHED,
  failed: NEW,
  declined: NEW,
  cancelled: NEW,
  removed: REMOVED,
};

const ORGS = {
  BASE: 'ORGS',
  TASKS: 'tasks',
  AGENTS: 'agents',
};

export const RTDB_CONSTANTS = {
  MERCHANTS,
  STATUS,
  STATUS_MAP,
  ORGS,
};
