import { map, catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

// services
import { TrackerService } from 'src/app/services/tracker/tracker.service';

// state
import * as TrackerActions from './tracker.actions';

@Injectable()
export class TrackerEffects {
  constructor(private actions$: Actions, private trackerService: TrackerService) {}

  getTask$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TrackerActions.GET_TASK),
        mergeMap(({ taskId }) =>
          this.trackerService.getTask(taskId).pipe(
            map((order: any) => {
              return TrackerActions.setTask({ task: order });
            }),
            catchError((error) => of(TrackerActions.error({ error })))
          )
        )
      ),
    { dispatch: true }
  );

  setRating$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TrackerActions.SET_RATING),
        mergeMap(({ rating }) =>
          this.trackerService.setRating(rating).pipe(
            map((response: any) => {
              return TrackerActions.ratingSuccess({ ratingResponse: response });
            }),
            catchError((error) => of(TrackerActions.error({ error: error.message })))
          )
        )
      ),
    { dispatch: true }
  );

  getRating$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TrackerActions.GET_RATING),
        mergeMap(({ taskId }) =>
          this.trackerService.getRating(taskId).pipe(
            map((ratingResponse: any) => {
              return TrackerActions.ratingSuccess({ ratingResponse });
            }),
            catchError((error) => of(TrackerActions.error({ error: error.message })))
          )
        )
      ),
    { dispatch: true }
  );
}
