import { map, catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

import { AuthService } from 'src/app/services/firebase/auth.service';

import * as ManagementActions from './management.actions';
import { RTDBService } from 'src/app/services/firebase/rtdb.service';
import { RealTimeOrder } from 'src/app/models/data/order.model';

@Injectable()
export class ManagementEffects {
  getDailyPriorities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ManagementActions.GET_DAILY_PRIORITIES),
        mergeMap(({ year, month }) =>
          this.rtdbService.getDailyPriorities(year, month).pipe(
            map((daily: any) => {
              return ManagementActions.setDailyPriorities({ daily });
            }),
            catchError((err) => of(ManagementActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  getMonthlyPriorities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ManagementActions.GET_MONTHLY_PRIORITIES),
        mergeMap(({ year }) =>
          this.rtdbService.getMonthlyPriorities(year).pipe(
            map((monthly: any) => {
              return ManagementActions.setMonthlyPriorities({ monthly });
            }),
            catchError((err) => of(ManagementActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  getWeeklyPriorities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ManagementActions.GET_WEEKLY_PRIORITIES),
        mergeMap(({ year }) =>
          this.rtdbService.getWeeklyPriorities(year).pipe(
            map((weekly: any) => {
              return ManagementActions.setWeeklyPriorities({ weekly });
            }),
            catchError((err) => of(ManagementActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );
  constructor(private actions$: Actions, private authService: AuthService, private rtdbService: RTDBService) {}
}
