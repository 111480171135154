import { Platform } from './../../models/data/platform.model';
import { createAction, props } from '@ngrx/store';
import { OrderDetails } from 'src/app/models/data/order.model';
import { DeliveryDetails } from 'src/app/models/integration/delivery.model';

export const CANCEL_DELIVERY_ORDER = '[Integration] Cancel Delivery Order';
export const REMOVE_DELIVERY_ORDER = '[Integration] Remove Delivery Order';
export const GET_DELIVERY_PRICING = '[Integration] Get Delivery Pricing';
export const SET_DELIVERY_PRICING = '[Integration] Set Delivery Pricing';
export const GET_DELIVERY_COURIER = '[Integration] Get Delivery Courier';
export const SET_DELIVERY_COURIER = '[Integration] Set Delivery Courier';
export const INITIALIZE_DELIVERY_PRICING = '[Integration] Initialize Delivery Pricing';

export const SET_SELECTED_DELIVERY_PLATFORM = '[Integration] Set Selected Delivery Platform';

export const ASSIGN_DELIVERY_PLATFORM = '[Integration] Assign Delivery Platform';
export const SET_DELIVERY_DETAILS = '[Integration] Set Delivery Detais';
export const SET_DELIVERY_DETAILS_SUCCESS = '[Integration] Set Delivery Detais Success';
export const UPDATE_ORDER_DETAILS = '[Integration] Update Order Detais';
export const UPDATE_ORDER_DETAILS_SUCCESS = '[Integration] Update Order Detais Success';

export const RESET_INTEGRATION = '[Integration] Reset Integration';

export const SUCCESS = '[Integration] Success';
export const ERROR = '[Integration] Error';

export const getDeliveryPricing = createAction(
  GET_DELIVERY_PRICING,
  props<{ orderDetails: OrderDetails; merchantOwnerUid: string }>()
);
export const setDeliveryPricing = createAction(SET_DELIVERY_PRICING, props<{ pricing: any }>());
export const initializeDeliveryPricing = createAction(INITIALIZE_DELIVERY_PRICING);

export const setSelectedDeliveryPlatform = createAction(
  SET_SELECTED_DELIVERY_PLATFORM,
  props<{ platform: Platform }>()
);
export const cancelDeliveryOrder = createAction(
  CANCEL_DELIVERY_ORDER,
  props<{ platform: string; courierOrderId: string; orderId: string; merchantOwnerUid: string }>()
);
export const assignDeliveryPlatform = createAction(
  ASSIGN_DELIVERY_PLATFORM,
  props<{ platform: Platform; merchantOwnerUid: string }>()
);
export const getDeliveryCourier = createAction(
  GET_DELIVERY_COURIER,
  props<{ platform: Platform; courierOrderId: string; merchantOwnerUid: string }>()
);
export const setDeliveryCourier = createAction(SET_DELIVERY_COURIER, props<{ courier: any }>());
export const removeDeliveryOrder = createAction(REMOVE_DELIVERY_ORDER, props<{ orderId: string }>());
export const setDeliveryDetails = createAction(SET_DELIVERY_DETAILS, props<{ deliveryOrder: any; orderId: string }>());
export const setDeliveryDetailsSuccess = createAction(SET_DELIVERY_DETAILS_SUCCESS);
export const updateOrderDetails = createAction(
  UPDATE_ORDER_DETAILS,
  props<{ branchUid: string; orderDetails: OrderDetails; deliveryOrder: DeliveryDetails }>()
);
export const updateOrderDetailsSuccess = createAction(UPDATE_ORDER_DETAILS_SUCCESS);

export const reset = createAction(RESET_INTEGRATION);

export const success = createAction(SUCCESS);
export const error = createAction(ERROR, props<{ error: Error }>());
