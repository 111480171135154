import { createAction, props } from '@ngrx/store';
import { Rating } from '../../models/v2/rating.model';

// GET ORDER
export const GET_TASK = '[TRACKER] Get Task';

// SET ORDER
export const SET_TASK = '[TRACKER] Set Task';

// Rating
export const GET_RATING = '[TRACKER] Get Rating';

export const SET_RATING = '[TRACKER] Set Rating';

export const GET_RATING_SUCCESS = '[TRACKER] Get Rating Success';

export const SET_RATING_SUCCESS = '[TRACKER] Set Rating Success';

export const RESET_TRACKER = '[TRACKER] Reset Tracker';

export const ERROR = '[TRACKER] Error';

// GET ORDER
export const getTask = createAction(GET_TASK, props<{ taskId: string }>());

// SET ORDER
export const setTask = createAction(SET_TASK, props<{ task: any }>());

// Rating

export const getRating = createAction(GET_RATING, props<{ taskId: string }>());

export const setRating = createAction(SET_RATING, props<{ rating: Rating }>());

export const ratingSuccess = createAction(SET_RATING_SUCCESS, props<{ ratingResponse: any }>());

export const reset = createAction(RESET_TRACKER);

export const error = createAction(ERROR, props<{ error: string }>());
