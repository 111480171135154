// Utils
import * as _ from 'lodash';

// Constants
import { RTDB_CONSTANTS } from 'src/app/core/constants/rtdb.constants';

// Models
import { Aggregation } from 'src/app/models/aggregation/aggregation.model';
import {
  RealTimeOrder,
  RealTimeOrders,
  OrdersByCategory,
  OrdersByDates,
  OrdersDetails,
} from 'src/app/models/data/order.model';

// Services
import { ProgressService } from 'src/app/services/utils/status.service';

// State
import { Store } from '@ngrx/store';
import { StoreRootState } from 'src/app/state/state.reducers';
import * as operationActions from 'src/app/state/dashboard/operation/operation.actions';
import { createSelector, props } from '@ngrx/store';
import { State } from './operation.reducer';

const MERCHANTS = RTDB_CONSTANTS.MERCHANTS;

export const selectState = (state: StoreRootState) => state.dashboard.operation;

// Real Time Orders
export const selectRealTimeOrders = createSelector(selectState, (operation: State) => operation.realTimeOrders);

// Orders by Category
export const selectOrdersSpecific = (category: string) => {
  return createSelector(selectRealTimeOrders, (realTimeOrders: RealTimeOrders) => {
    const orders = realTimeOrders?.orders as RealTimeOrder[];
    return orders.filter((order) => ProgressService.mapToStatus(order.status) === category);
  });
};

export const selectNewOrders = selectOrdersSpecific(MERCHANTS.NEW);
export const selectInProgressOrders = selectOrdersSpecific(MERCHANTS.INPROGRESS);
export const selectFinishedOrders = selectOrdersSpecific(MERCHANTS.FINISHED);
export const selectRemovedOrders = selectOrdersSpecific(MERCHANTS.REMOVED);

export const selectFirstOrder = createSelector(
  selectNewOrders,
  selectInProgressOrders,
  selectFinishedOrders,
  selectRemovedOrders,
  (
    newOrders: RealTimeOrder[],
    inprogressOrders: RealTimeOrder[],
    finishedOrders: RealTimeOrder[],
    removedOrders: RealTimeOrder[]
  ) => {
    return _.head(newOrders) || _.head(inprogressOrders) || _.head(finishedOrders) || _.head(removedOrders);
  }
);

export const selectOrders = createSelector(
  selectNewOrders,
  selectInProgressOrders,
  selectFinishedOrders,
  selectRemovedOrders,
  (
    newOrders: RealTimeOrder[],
    inprogressOrders: RealTimeOrder[],
    finishedOrders: RealTimeOrder[],
    removedOrders: RealTimeOrder[]
  ) => {
    const ordersByCategory: OrdersByCategory = {
      new: newOrders,
      inprogress: inprogressOrders,
      finished: finishedOrders,
      removed: removedOrders,
    };
    return ordersByCategory;
  }
);

// Orders By Dates
export const selectOrdersByDates = createSelector(selectState, (operation: State) => operation.ordersByDates);

export const selectOrdersByDate = createSelector(
  selectOrdersByDates,
  (ordersByDates: OrdersByDates, dateProps: { date: string }) => ordersByDates.orders[dateProps.date]
);

// Order Details
export const selectOrdersDetails = createSelector(selectState, (operation: State) => operation.ordersDetails);

export const selectOrderDetails = createSelector(
  selectOrdersDetails,
  (ordersDetails: OrdersDetails, orderProps: { id: string }) => ordersDetails.orders[orderProps.id]
);

// Selected Real Time Order
export const selectSelectedRealTimeOrder = createSelector(
  selectState,
  (operation: State) => operation.selectedRealTimeOrder
);

export const selectSelectedOrderDetails = createSelector(
  selectState,
  (operation: State) => operation.selectedOrderDetails
);

// Filter
export const selectSelectedDateFilter = createSelector(selectState, (operation: State) => operation.selectedDateFilter);

// Selected Branch
export const selectSelectedBranch = createSelector(selectState, (operation: State) => operation.selectedBranch);

// Branch + Date FIlter
export const selectSelectedBranchAndDateFilter = createSelector(
  selectSelectedDateFilter,
  selectSelectedBranch,
  (dateFilter: string, selectedBranch: string) => ({ dateFilter, selectedBranch })
);
