import { createAction, props } from '@ngrx/store';

import { ContentStructure } from 'src/app/models/structure/content.structure.model';

export const SET_CONTENT = '[Content] Set Content';
export const RESET_CONTENT = '[Content] Reset Content';

export const SUCCESS = '[Content] Success';
export const ERROR = '[Content] Error';

export const setContent = createAction(SET_CONTENT, props<{ content: ContentStructure }>());
export const reset = createAction(RESET_CONTENT);

export const error = createAction(ERROR, props<{ error: Error }>());
