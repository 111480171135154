import { createAction, props } from '@ngrx/store';

// Models
import { RealTimeOrder, OrderDetails } from 'src/app/models/data/order.model';
import { OrderAggregation } from 'src/app/models/aggregation/order.aggregation.model';

// Actions
export const GET_REALTIME_ORDERS = '[Dashboard - Operation] Get Real Time Orders';
export const SET_REALTIME_ORDERS = '[Dashboard - Operation] Set Real Time Orders';
export const INITIALIZE_REALTIME_ORDERS = '[Dashboard - Operation] Initialize Real Time Orders';

export const GET_ORDER_DETAILS = '[Dashboard - Operation] Get Order Details';
export const SET_ORDER_DETAILS = '[Dashboard - Operation] Set Order Details';

export const SET_SELECTED_REAL_TIME_ORDER = '[Dashboard - Operation] Set Selected Real Time Order';
export const SET_SELECTED_ORDER_DETAILS = '[Dashboard - Operation] Set Selected Order Details';
export const INITIALIZE_SELECTED_ORDER_DETAILS = '[Dashboard - Operation] Initialize Selected Order Details';

export const SET_SELECTED_DATE_FITLER = '[Dashboard - Operation] Set Selected Date Filter';
export const SET_SELECTED_BRANCH = '[Dashboard - Operation] Set Selected Branch';

export const RESET_OPERATION = '[Content] Reset Operation';

export const SUCCESS = '[Dashboard - Operation] Success';
export const ERROR = '[Dashboard - Operation] Error';

export const getOrderDetails = createAction(GET_ORDER_DETAILS, props<{ id: string }>());
export const setOrderDetails = createAction(SET_ORDER_DETAILS, props<{ orderDetails: OrderDetails }>());

export const getRealTimeOrders = createAction(
  GET_REALTIME_ORDERS,
  props<{ selectedBranch: string; dateFilter: string }>()
);

export const setRealTimeOrders = createAction(SET_REALTIME_ORDERS, props<{ orders: RealTimeOrder[] }>());

export const initializeRealTimeOrders = createAction(INITIALIZE_REALTIME_ORDERS);

export const setSelectedRealTimeOrder = createAction(
  SET_SELECTED_REAL_TIME_ORDER,
  props<{ selectedRealTimeOrder?: RealTimeOrder }>()
);

export const setSelectedOrderDetails = createAction(
  SET_SELECTED_ORDER_DETAILS,
  props<{ selectedOrderDetails: OrderDetails }>()
);

export const initializeSelectedOrderDetails = createAction(INITIALIZE_SELECTED_ORDER_DETAILS);

export const setSelectedDateFilter = createAction(SET_SELECTED_DATE_FITLER, props<{ selectedDateFilter: string }>());

export const setSelectedBranch = createAction(SET_SELECTED_BRANCH, props<{ selectedBranch: string }>());

export const reset = createAction(RESET_OPERATION);

export const error = createAction(ERROR, props<{ error: Error }>());
export const success = createAction(SUCCESS);
