import { Injectable } from '@angular/core';
import { forkJoin, of, from } from 'rxjs';

import { OrderDetails } from 'src/app/models/data/order.model';
import { Platform } from 'src/app/models/data/platform.model';

import { LalamoveService } from 'src/app/services/integration/delivery/lalamove.service';
import { MrspeedyService } from 'src/app/services/integration/delivery/mrspeedy.service';

@Injectable({
  providedIn: 'root',
})
export class DeliveryService {
  constructor(private lalamoveService: LalamoveService, private mrspeedyService: MrspeedyService) {}

  generateQuotes(orderDetails: OrderDetails, merchantOwnerUid: string) {
    return forkJoin({
      // lalamove: this.lalamoveService.quote(orderDetails),
      mrspeedy: this.mrspeedyService.quote(orderDetails, merchantOwnerUid),
    });
  }

  cancelOrder(platform: string, courierOrderId: string, orderId: string, merchantOwnerUid: string, branchUid: string) {
    return from(
      this[`${platform}Service`].cancel(courierOrderId, orderId, merchantOwnerUid, branchUid) as Promise<any>
    );
  }

  assignPlatform(platform: Platform, orderDetails: OrderDetails, merchantOwnerUid: any) {
    return from(this[`${platform.id}Service`].assign(orderDetails, merchantOwnerUid) as Promise<any>);
  }

  getCourier(platform: string, courierOrderId: string, merchantOwnerUid: string) {
    return from(this[`${platform}Service`].getCourier(courierOrderId, merchantOwnerUid) as Promise<any>);
  }
}
