import { Injectable, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import * as firebase from 'firebase/app';
import { auth } from 'firebase/app';

import { user } from 'rxfire/auth';
import { docData } from 'rxfire/firestore';
import { tap, switchMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

import { NotificationService } from './../utils/notification.service';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  db = firebase.firestore();
  authClient = firebase.auth();
  analytics = firebase.analytics();

  user$: Observable<any>;
  userDoc$: Observable<any>;

  user;
  userDoc;

  constructor(
    private router: Router,
    private notif: NotificationService,
    private ngZone: NgZone,
    private app: ApplicationRef
  ) {
    this.user$ = user(this.authClient).pipe(
      tap((u) => {
        if (u) {
          this.user = u;
          this.analytics.setUserId(u.uid);
        } else {
          this.user = null;
        }
        // this.app.tick();
      })
    );

    this.userDoc$ = this.getUserDoc$('merchants').pipe(
      tap((u) => {
        if (u) {
          this.userDoc = u;
        }
        // this.app.tick();
      })
    );
  }

  getUserDoc$(col: string) {
    return user(this.authClient).pipe(
      switchMap((u) => {
        return u ? docData(firebase.firestore().doc(`${col}/${(u as any).uid}`)) : of(null);
      })
    );
  }

  signOut() {
    const res = this.authClient.signOut();
    this.ngZone.run(() => this.router.navigate(['auth/login'])).then();
    this.notif.showSuccess('Signout success!');
    this.analytics.logEvent('logout', {});
    return of(res);
  }

  async googleConnect(): Promise<any> {
    const credential = this.authClient.signInWithPopup(new auth.GoogleAuthProvider());
    return this.loginHandler(credential);
  }

  async facebookConnect(): Promise<any> {
    const credential = this.authClient.signInWithPopup(new auth.FacebookAuthProvider());
    return this.loginHandler(credential);
  }

  async appleLogin() {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    const credential = this.authClient.signInWithPopup(provider);
    return this.loginHandler(credential);
  }

  get userId() {
    return this.user ? this.user.uid : null;
  }

  async emailSignup(email: string, password: string) {
    const credential = this.authClient.createUserWithEmailAndPassword(email, password).then((res) => {
      const mer_ref = firebase.firestore().collection('merchants');
      const data = {
        address: 'Vito Cruz',
        company: 'Thai Mango',
        contact: '09999999999',
        displayName: 'Kim',
        email: email,
        key: 'tm',
        photoUrl: 'image.jpg',
        role: 'Owner',
      };
      mer_ref
        .doc(res.user.uid)
        .set(data)
        .then(() => {
          console.log('Merchant account created in RTDB');
        })
        .catch((error) => {
          alert(error);
        });
    });
    return this.loginHandler(credential);
  }

  async emailLogin(email: string, password: string) {
    const credential = this.authClient.signInWithEmailAndPassword(email, password);
    return this.loginHandler(credential);
  }

  async resetPassword(email: string) {
    return this.authClient.sendPasswordResetEmail(email);
  }

  async loginHandler(promise: Promise<any>) {
    let res, serverError;
    try {
      res = await promise;
      this.notif.showSuccess('Login Success!');
      this.ngZone.run(() => this.router.navigate(['/dashboard/operation'])).then();
      this.analytics.logEvent('login', {});
    } catch (err) {
      serverError = err.message;
      this.notif.showDanger(err.message);
    }
    return { res, serverError };
  }
}
