import { Action, createReducer, on } from '@ngrx/store';
import * as ContentActions from './content.actions';

import { ContentStructure } from 'src/app/models/structure/content.structure.model';

export interface State extends ContentStructure {
  error: Error;
}

export const initialState: State = {
  error: null,
};

const contentReducer = createReducer(
  initialState,
  on(ContentActions.setContent, (state, { content }) => ({
    ...state,
    ...content,
    error: null,
  })),
  on(ContentActions.reset, () => ({ ...initialState })),
  on(ContentActions.error, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return contentReducer(state, action);
}
