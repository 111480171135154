import { Action, createReducer, on } from '@ngrx/store';
import { PrioritiesByDates, RealTimePriorities } from 'src/app/models/data/priority.model';
import * as ManagementActions from './management.actions';
import * as Time from 'src/app/core/utils/time';

export interface State {
  daily: object[];
  weekly: object[];
  monthly: object[];
  // Real Time Priorities
  realTimePriorities: RealTimePriorities;
  // Filter
  selectedPriorityFilter: string; // priority
  selectedDateFilter: string; // YYYY/M/D
  // SelectedBranch
  selectedBranch: string;
  prioritiesByDates: PrioritiesByDates;
}

export const initialState: State = {
  daily: null,
  weekly: null,
  monthly: null,
  realTimePriorities: {
    loading: true,
    priorities: [],
  },
  selectedPriorityFilter: '',
  selectedDateFilter: Time.moment$().format(Time.defaultFormat),
  selectedBranch: '',
  prioritiesByDates: {
    loading: true,
    priorities: {},
  },
};

const operationReducer = createReducer(
  initialState,
  on(ManagementActions.getOverdueOrders, (state) => state),
  on(ManagementActions.getCriticalOrders, (state) => state),
  on(ManagementActions.getUrgentOrders, (state) => state),
  on(ManagementActions.getNormalOrders, (state) => state),

  on(ManagementActions.getDailyPriorities, (state) => state),
  on(ManagementActions.setDailyPriorities, (state, month) => ({
    ...state,
    daily: {
      ...month.daily,
    },
    error: null,
  })),
  on(ManagementActions.getWeeklyPriorities, (state) => state),
  on(ManagementActions.getMonthlyPriorities, (state) => state),
  on(ManagementActions.setWeeklyPriorities, (state, weekly) => ({
    ...state,
    weekly: {
      ...weekly.weekly,
    },
    error: null,
  })),
  on(ManagementActions.setMonthlyPriorities, (state, month) => ({
    ...state,
    monthly: {
      ...month.monthly,
    },
    error: null,
  })),

  // Real Time Priorities
  on(ManagementActions.getOrdersPriorities, (state) => state),
  on(ManagementActions.setOrdersPriorities, (state, { priorities }) => ({
    ...state,
    realTimePriorities: {
      priorities,
      loading: false,
    },
    error: null,
  })),

  // Priority Filter
  on(ManagementActions.setSelectedPriorityFilter, (state, { selectedPriorityFilter }) => ({
    ...state,
    error: null,
    selectedPriorityFilter,
  })),

  // Date Filter
  on(ManagementActions.setSelectedDateFilter, (state, { selectedDateFilter }) => ({
    ...state,
    error: null,
    selectedDateFilter,
  })),

  // Selected Branch
  on(ManagementActions.setSelectedBranch, (state, { selectedBranch }) => ({
    ...state,
    error: null,
    selectedBranch,
  })),
  // Reset
  on(ManagementActions.reset, () => ({ ...initialState })),
  on(ManagementActions.error, (state) => state)
);

export function reducer(state: State | undefined, action: Action) {
  return operationReducer(state, action);
}
