import { Location, DatePipe } from '@angular/common';
import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { AuthService } from 'src/app/services/firebase/auth.service';
import { IDUtilService } from 'src/app/services/utils/id.util.service';
import { MapService } from 'src/app/services/google/map.service';
import { ROUTES } from '../sidebar/sidebar.component';
import { TranslateService } from '@ngx-translate/core';

import { Task, Delivery, Pickup } from 'src/app/models/data/task.model';
import { BasicUser } from 'src/app/models/data/user.model';

const misc: any = {
  sidebar_mini_active: true,
};

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  // forms
  focus = {
    delivery: {
      mobile: false,
      email: false,
      name: false,
      address: false,
      notes: false,
      dateBefore: false,
      items: false,
    },
    pickup: {
      dateBefore: false,
    },
  };

  validationMessages: any;

  // forms
  public searchPlace = '';
  public places = [];

  private listTitles: any[];
  location: Location;
  public state = true;
  public dashboardColor = true;

  private toggleButton: any;
  public isCollapsed = true;

  taskForm: FormGroup;
  pickupForm: any;
  texts: any;

  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    public toastr: ToastrService,
    public auth: AuthService,
    private fb: FormBuilder,
    private idUtil: IDUtilService,
    public datepipe: DatePipe,
    private mapService: MapService,
    private translate: TranslateService
  ) {
    this.location = location;
    this.auth.userDoc$.subscribe((data) => {
      if (data) {
        // merchant data
        this.pickupForm = {
          mobile: data.contact,
          email: data.email,
          name: data.displayName,
          address: data.address,
        };
      }
    });
    this.taskForm = this.fb.group({
      delivery: this.fb.group({
        mobile: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        name: ['', Validators.required],
        address: ['', Validators.required],
        notes: ['', Validators.required],
        dateBefore: ['', Validators.required],
        items: ['', Validators.required],
      }),
      pickup: this.fb.group({
        dateBefore: ['', Validators.required],
      }),
    });
  }

  get delivery() {
    return this.taskForm.get('delivery');
  }

  get pickup() {
    return this.taskForm.get('pickup');
  }

  get dvMobile() {
    return this.delivery.get('mobile');
  }
  get dvEmail() {
    return this.delivery.get('email');
  }
  get dvName() {
    return this.delivery.get('name');
  }
  get dvAddress() {
    return this.delivery.get('address');
  }
  get dvNotes() {
    return this.delivery.get('notes');
  }
  get dvDateBefore() {
    return this.delivery.get('dateBefore');
  }
  get puDateBefore() {
    return this.pickup.get('dateBefore');
  }
  get puDvItems() {
    return this.delivery.get('items');
  }

  ngOnInit() {
    window.addEventListener('resize', this.updateColor);
    this.listTitles = ROUTES.filter((listTitle) => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
    });
    this.initializeTranslation();
    this.initializeValidationMessages();
  }

  initializeTranslation() {
    this.translate.get('NAV.validationMessages').subscribe((texts) => {
      this.texts = texts;
    });
  }

  initializeValidationMessages() {
    this.validationMessages = {
      delivery: {
        mobile: [{ type: 'required', message: `${this.texts.mobile}` }],
        email: [{ type: 'required', message: `${this.texts.email}` }],
        name: [{ type: 'required', message: `${this.texts.name}` }],
        address: [{ type: 'required', message: `${this.texts.address}` }],
        dateBefore: [{ type: 'required', message: `${this.texts.deliveryDateBefore}` }],
        items: [{ type: 'required', message: `${this.texts.items}` }],
      },
      pickup: {
        dateBefore: [{ type: 'required', message: `${this.texts.pickupDateBefore}` }],
      },
    };
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor() {
    const navbar = document.getElementsByClassName('navbar')[0];
    if (window.innerWidth < 993 && !this.isCollapsed) {
      navbar.classList.add('bg-white');
      navbar.classList.remove('navbar-transparent');
    } else {
      navbar.classList.remove('bg-white');
      navbar.classList.add('navbar-transparent');
    }
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('sidebar-mini')) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;
      // this.showSidebarMessage("Sidebar mini deactivated...");
    } else {
      body.classList.add('sidebar-mini');
      // this.showSidebarMessage("Sidebar mini activated...");
      misc.sidebar_mini_active = true;
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }
  showSidebarMessage(message) {
    this.toastr.show('<span data-notify="icon" class="tim-icons icon-bell-55"></span>', message, {
      timeOut: 4000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-top-right',
    });
  }
  ngOnDestroy() {
    window.removeEventListener('resize', this.updateColor);
  }
  getTitle() {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }

    for (let item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = <HTMLElement>document.getElementsByTagName('body')[0];
    const html = document.getElementsByTagName('html')[0];
    if (window.innerWidth < 991) {
      body.style.position = 'fixed';
    }

    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);

    html.classList.add('nav-open');
    const $layer = document.createElement('div');
    $layer.setAttribute('id', 'bodyClick');

    if (html.getElementsByTagName('body')) {
      document.getElementsByTagName('body')[0].appendChild($layer);
    }
    const $toggle = document.getElementsByClassName('navbar-toggler')[0];
    $layer.onclick = function () {
      // asign a function
      html.classList.remove('nav-open');
      setTimeout(function () {
        $layer.remove();
        $toggle.classList.remove('toggled');
      }, 400);
      const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];

      if (window.innerWidth < 991) {
        setTimeout(function () {
          mainPanel.style.position = '';
        }, 500);
      }
    }.bind(this);

    html.classList.add('nav-open');
  }
  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    this.toggleButton?.classList.remove('toggled');
    const body = <HTMLElement>document.getElementsByTagName('body')[0];

    if (window.innerWidth < 991) {
      setTimeout(function () {
        body.style.position = '';
      }, 500);
    }
    const $layer: any = document.getElementById('bodyClick');
    if ($layer) {
      $layer.remove();
    }
    html.classList.remove('nav-open');
  }

  searchPlaceListOnClick(place) {
    this.searchPlace = place;
    this.places = [];
  }

  searchPlacesPredictions() {
    if (this.searchPlace !== '') {
      this.mapService.getPlacePredictions(this.searchPlace).subscribe((places_predictions) => {
        this.places = places_predictions;
      });
    } else {
      this.places = [];
    }
  }

  createTask() {
    const pickupUser: BasicUser = {
      uid: this.idUtil.getShortId('cu'),
      name: this.pickupForm.name,
      email: this.pickupForm.email,
      mobile: this.pickupForm.mobile,
    };
    const deliveryUser: BasicUser = {
      uid: this.idUtil.getShortId('cu'),
      name: this.taskForm.value.delivery.name,
      email: this.taskForm.value.delivery.email,
      mobile: this.taskForm.value.delivery.mobile,
    };

    const pickup: Pickup = {
      id: this.idUtil.getShortId('pu'),
      status: 'unassigned',
      customer: pickupUser,
      dateTime: moment(this.taskForm.value.delivery.puDateBefore).format('MMMM Do YYYY, h:mm a'),
      address: {
        lat: '14.5995',
        lng: '120.9842',
        location: this.pickupForm.address,
      },
      items: this.taskForm.value.delivery.items,
    };
    const delivery: Delivery = {
      id: this.idUtil.getShortId('dv'),
      status: 'unassigned',
      customer: deliveryUser,
      dateTime: moment(this.taskForm.value.delivery.dateBefore).format('MMMM Do YYYY, h:mm a'),
      notes: this.taskForm.value.delivery.notes,
      address: {
        lat: '14.5995',
        lng: '120.9842',
        location: this.taskForm.value.delivery.address,
      },
      items: this.taskForm.value.delivery.items,
    };
    const taskForm: Task = {
      delivery: delivery,
      pickup: pickup,
      courier: '',
      type: 'delivery',
    };
  }

  changeDashboardColor(color) {
    const body = document.getElementsByTagName('body')[0];
    if (body && color === 'white-content') {
      body.classList.add(color);
      body.classList.remove('dark-content');
    } else if (body.classList.contains('white-content')) {
      body.classList.remove('white-content');
      body.classList.add('dark-content');
    }
  }

  onChangeDashboardColor(event) {
    const body = document.getElementsByTagName('body')[0];
    if (this.dashboardColor === true) {
      this.changeDashboardColor('dark-content');
    } else {
      this.changeDashboardColor('white-content');
    }
    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  onChange(event) {
    const body = document.getElementsByTagName('body')[0];
    if (this.state === true) {
      body.classList.remove('sidebar-mini');
      this.showSidebarMessage('Sidebar mini deactivated...');
    } else {
      body.classList.add('sidebar-mini');
      this.showSidebarMessage('Sidebar mini activated...');
    }
    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }
}
