import { StoreRootState } from 'src/app/state/state.reducers';
import { createSelector } from '@ngrx/store';

import { ContentStructure } from 'src/app/models/structure/content.structure.model';
import { IntegrationStructure } from 'src/app/models/structure/integration.structure.model';
import { Translations } from 'src/app/models/data/translations.model';

export const selectContent = (state: StoreRootState) => state?.content;

export const selectContentDefaults = createSelector(selectContent, (content: ContentStructure) => content?.defaults);

export const selectContentDashboardOperation = createSelector(
  selectContent,
  (content: ContentStructure) => content?.dashboard?.operation
);

export const selectContentDashboardManagement = createSelector(
  selectContent,
  (content: ContentStructure) => content?.dashboard?.management
);

export const selectContentIntegration = createSelector(
  selectContent,
  (content: ContentStructure) => content?.integration
);

export const selectContentIntegrationOrder = createSelector(
  selectContentIntegration,
  (integration: IntegrationStructure) => integration?.order
);

export const selectContentIntegrationDelivery = createSelector(
  selectContentIntegration,
  (integration: IntegrationStructure) => integration?.delivery
);

export const selectContentReports = createSelector(selectContent, (content: ContentStructure) => content?.reports);

export const selectContentSettings = createSelector(selectContent, (content: ContentStructure) => content?.settings);

export const selectContentTranslations = createSelector(
  selectContent,
  (content: ContentStructure) => content?.translations
);

export const selectContentTranslationsShared = createSelector(
  selectContentTranslations,
  (content: Translations) => content?.SHARED
);

export const selectContentTranslationsNav = createSelector(
  selectContentTranslations,
  (content: Translations) => content?.NAV
);

export const selectContentTranslationsAuth = createSelector(
  selectContentTranslations,
  (content: Translations) => content?.AUTH
);

export const selectContentTranslationsDashboard = createSelector(
  selectContentTranslations,
  (content: Translations) => content?.DASHBOARD
);

export const selectContentTranslationsSettings = createSelector(
  selectContentTranslations,
  (content: Translations) => content?.SETTINGS
);

export const selectContentTranslationsReports = createSelector(
  selectContentTranslations,
  (content: Translations) => content?.REPORTS
);

export const selectContentTracking = createSelector(selectContent, (content: ContentStructure) => content?.tracking);
