import { map, catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

import { AuthService } from 'src/app/services/firebase/auth.service';

import * as SettingsActions from './settings.actions';

@Injectable()
export class SettingsEffects {
  constructor(private actions$: Actions, private authService: AuthService) {}
}
