import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Rating } from '../../models/v2/rating.model';
import { FirestoreService } from '../firebase/firestore.service';
import { RTDBService } from '../firebase/rtdb.service';

@Injectable({
  providedIn: 'root',
})
export class TrackerService {
  constructor(private rtdb: RTDBService, private fs: FirestoreService) {}

  getTask(taskId: string) {
    return this.rtdb.getCustomerOrder(taskId);
  }

  setRating(rating: Rating) {
    return this.fs.setRating(rating);
  }

  getRating(taskId: string) {
    return this.fs.getRating(taskId);
  }
}
