import { Component, OnInit } from '@angular/core';

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  rtlTitle: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  smallTitle?: string;
  rtlTitle: string;
  rtlSmallTitle?: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  smallTitle?: string;
  rtlSmallTitle?: string;
  title?: string;
  rtlTitle: string;
  type?: string;
}
// Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    type: 'sub',
    icontype: 'tim-icons icon-notes',
    rtlTitle: 'لوحة القيادة',
    children: [
      {
        path: 'operation',
        rtlTitle: ' أشكال منتظمة',
        rtlSmallTitle: 'ص ',
        title: 'Operation',
        type: 'link',
        smallTitle: 'OP',
      },
      {
        path: 'management',
        rtlTitle: ' أشكال موسعة',
        rtlSmallTitle: ' هوو',
        title: 'Management',
        type: 'link',
        smallTitle: 'MA',
      },
    ],
  },
  {
    path: '/reports',
    title: 'Reports',
    type: 'link',
    rtlTitle: 'الرسوم البيانية',
    icontype: 'tim-icons icon-chart-bar-32',
  },
  {
    path: '/settings',
    title: 'Settings',
    type: 'link',
    rtlTitle: 'الحاجيات',
    icontype: 'tim-icons icon-settings',
  },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() {}

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }
}
