export const DEFAULT = {
  PUBLIC_STORAGE: 'https://storage.googleapis.com/zipub',
  MARKERS: {
    MERCHANT: 'marker-merchant.png',
    CUSTOMER: 'marker-customer.png',
    AGENT: 'marker-agent.png',
  },
};

export const ENVIRONMENTS = {
  DEV: 'DEV',
  STAGE: 'STAGE',
  PROD: 'PROD',
};

export const SUB_DOMAINS = {
  DEV: 'dev.',
  STAGE: 'stage.',
  PROD: '', // prod has no suburl
};

export const BASE_URL = {
  ZIPELI: 'zipeli.com',
};

export const APPS_URL = {
  CUSTOMER: {
    BASE: `customer.${BASE_URL.ZIPELI}`,
    TRACK: `track?id`,
    GET_TRACKING_URL: (subDomain: string, id: string) =>
      `https://${subDomain}${APPS_URL.CUSTOMER.BASE}/${APPS_URL.CUSTOMER.TRACK}=${id}`,
  },
  MERCHANT: `merchant.${BASE_URL.ZIPELI}`,
};

export const BUX_URL = {
  DEV: 'https://bux.ph/test/checkout/',
  STAGE: 'https://bux.ph/test/checkout/',
  PROD: 'https://bux.ph/checkout/',
};

export const ORDER_STATUS = {
  UNASSIGN: 0,
  ACKNOWLEDGE: 2,
  SUCCESSFUL: 5,
};
