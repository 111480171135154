import { Action, createReducer, on } from '@ngrx/store';
import { Rating } from 'src/app/models/v2/rating.model';
import * as TrackerActions from './tracker.actions';

export interface State {
  task: object;
  rating: Rating;
  ratingResponse: object;
  error: Error;
}

export const initialState: State = {
  task: {
    loading: true,
    value: null,
  },

  rating: {
    agent: null,
    customer: null,
    loading: true,
  },

  ratingResponse: {
    loading: false,
    value: null,
  },

  error: null,
};

const trackerReducer = createReducer(
  initialState,
  // ORDERS
  on(TrackerActions.getTask, (state) => state),
  on(TrackerActions.setTask, (state, { task }) => ({
    ...state,
    task: {
      loading: false,
      ...task,
    },
    error: null,
  })),

  on(TrackerActions.getRating, (state) => state),
  on(TrackerActions.setRating, (state, { rating }) => ({
    ...state,
    rating: {
      loading: false,
      ...rating,
    },
    ratingResponse: {
      ...state.ratingResponse,
      loading: true,
    },
    error: null,
  })),

  on(TrackerActions.ratingSuccess, (state, { ratingResponse }) => ({
    ...state,
    ratingResponse: {
      loading: false,
      ...ratingResponse,
    },
    error: null,
  })),

  on(TrackerActions.reset, () => ({ ...initialState })),
  on(TrackerActions.error, (state, { error }) => ({
    ...state,
    error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return trackerReducer(state, action);
}
