import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DxVectorMapModule } from 'devextreme-angular';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { VectorMapComponent } from './vector-map/vector-map.component';
import { PictureUploadComponent } from './picture-upload/picture-upload.component';
import { AuthNavbarComponent } from './auth-navbar/auth-navbar.component';
import { RtlNavbarComponent } from './rtl-navbar/rtl-navbar.component';
import { RtlSidebarComponent } from './rtl-sidebar/rtl-sidebar.component';
import { FixedPluginComponent } from './fixed-plugin/fixed-plugin.component';

import { TranslateModule } from '@ngx-translate/core';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    JwBootstrapSwitchNg2Module,
    DxVectorMapModule,
    ReactiveFormsModule,
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    TranslateModule,
    TooltipModule.forRoot(),
  ],
  declarations: [
    FooterComponent,
    VectorMapComponent,
    NavbarComponent,
    SidebarComponent,
    PictureUploadComponent,
    AuthNavbarComponent,
    RtlNavbarComponent,
    RtlSidebarComponent,
    FixedPluginComponent,
    LoadingSpinnerComponent,
  ],
  exports: [
    FooterComponent,
    VectorMapComponent,
    NavbarComponent,
    SidebarComponent,
    PictureUploadComponent,
    AuthNavbarComponent,
    RtlNavbarComponent,
    RtlSidebarComponent,
    FixedPluginComponent,
    LoadingSpinnerComponent,
  ],
  providers: [DatePipe],
})
export class ComponentsModule {}
