import { Action, createReducer, on } from '@ngrx/store';
import * as AggregationActions from './aggregation.actions';
import { Aggregation } from 'src/app/models/aggregation/aggregation.model';

export interface State extends Aggregation {
  error: Error;
}

export const initialState: State = {
  order: {
    loading: true,
  },
  priority: {
    loading: true,
  },
  sales: {
    loading: true,
  },
  payment: {
    loading: true,
  },
  delivery: {
    loading: true,
  },
  error: null,
};

const integrationReducer = createReducer(
  initialState,
  // Orders
  on(AggregationActions.getOrdersAgregation, (state) => state),
  on(AggregationActions.setOrdersAgregation, (state, { aggregation }) => ({
    ...state,
    order: {
      ...state.order,
      ...aggregation,
      loading: false,
    },
    error: null,
  })),
  on(AggregationActions.initializeOrdersAggregation, (state) => ({
    ...state,
    error: null,
    order: {
      loading: true,
    },
  })),
  // Priorities
  on(AggregationActions.getPrioritiesAgregation, (state) => state),
  on(AggregationActions.setPrioritiesAgregation, (state, { aggregation }) => ({
    ...state,
    priority: {
      ...state.priority,
      ...aggregation,
      loading: false,
    },
    error: null,
  })),
  on(AggregationActions.initializePrioritiesAggregation, (state) => ({
    ...state,
    error: null,
    priority: {
      loading: true,
    },
  })),
  // Deliveries
  on(AggregationActions.getDeliveriesAgregation, (state) => state),
  on(AggregationActions.setDeliveriesAgregation, (state, { aggregation }) => ({
    ...state,
    delivery: {
      ...state.delivery,
      ...aggregation,
      loading: false,
    },
    error: null,
  })),
  on(AggregationActions.initializeDeliveriesAggregation, (state) => ({
    ...state,
    error: null,
    delivery: {
      loading: true,
    },
  })),
  on(AggregationActions.reset, () => ({ ...initialState })),
  on(AggregationActions.success, (state) => state),
  on(AggregationActions.error, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return integrationReducer(state, action);
}
