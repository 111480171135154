import { mergeMap, map, catchError, first, tap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
// Service
import { DeliveryService } from 'src/app/services/integration/delivery/delivery.service';
import { FirestoreService } from 'src/app/services/firebase/firestore.service';
// State
import { Store, select } from '@ngrx/store';
import { StoreRootState } from 'src/app/state/state.reducers';
import * as fromOperation from 'src/app/state/dashboard/operation/operation.selector';
import * as fromAuth from 'src/app/state/auth/auth.selector';
import * as IntegrationActions from './integration.actions';
// Models
import { Platform } from 'src/app/models/data/platform.model';
import { OrderDetails } from 'src/app/models/data/order.model';
import { RTDBService } from 'src/app/services/firebase/rtdb.service';

@Injectable()
export class IntegrationEffects {
  getDeliveryPricing$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.GET_DELIVERY_PRICING),
        mergeMap(({ orderDetails, merchantOwnerUid }) =>
          this.deliveryService.generateQuotes(orderDetails, merchantOwnerUid).pipe(
            map((pricing) => {
              return IntegrationActions.setDeliveryPricing({ pricing });
            }),
            catchError((err) => of(IntegrationActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  cancelDeliveryOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.CANCEL_DELIVERY_ORDER),
        mergeMap(({ platform, courierOrderId, orderId, merchantOwnerUid }) =>
          this.store.pipe(
            select(fromAuth.selectSelectedBranch),
            first(),
            map((branch) => {
              const branchUid = branch.id;
              return [platform, courierOrderId, orderId, merchantOwnerUid, branchUid];
            })
          )
        ),
        mergeMap(([platform, courierOrderId, orderId, merchantOwnerUid, branchUid]) =>
          this.deliveryService.cancelOrder(platform, courierOrderId, orderId, merchantOwnerUid, branchUid).pipe(
            map((pricing) => {
              return IntegrationActions.setDeliveryPricing({ pricing });
            }),
            catchError((err) => of(IntegrationActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  assignDeliveryPlatform$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.ASSIGN_DELIVERY_PLATFORM),
      mergeMap(({ platform, merchantOwnerUid }) =>
        this.store.pipe(
          select(fromOperation.selectSelectedOrderDetails),
          first(),
          map((orderDetails) => [platform, orderDetails, merchantOwnerUid])
        )
      ),
      mergeMap(([platform, orderDetails, merchantOwnerUid]) =>
        this.store.pipe(
          select(fromAuth.selectSelectedBranch),
          first(),
          map((branch) => {
            const branchUid = branch.uid;
            return [platform, orderDetails, branchUid, merchantOwnerUid];
          })
        )
      ),
      mergeMap(([platform, orderDetails, branchUid, merchantOwnerUid]) => {
        const _platform = platform as Platform;
        const _orderDetails = orderDetails as OrderDetails;
        const _branchUid = branchUid as string;
        return this.deliveryService.assignPlatform(_platform, _orderDetails, merchantOwnerUid).pipe(
          switchMap((_deliveryOrder) => {
            const orderId = _orderDetails.id;
            const deliveryOrder = _deliveryOrder.data.data;
            deliveryOrder['courier'] = _platform.id;
            deliveryOrder['courierOrderId'] = deliveryOrder?.order?.order_id;
            deliveryOrder['deliveryFee'] = deliveryOrder?.order?.delivery_fee_amount;
            return [
              IntegrationActions.setDeliveryDetails({ deliveryOrder, orderId }),
              IntegrationActions.updateOrderDetails({
                branchUid: _branchUid,
                orderDetails: _orderDetails,
                deliveryOrder,
              }),
            ];
          })
        );
      }),
      catchError((err) => of(IntegrationActions.error({ error: err.message })))
    )
  );

  setDeliveryDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.SET_DELIVERY_DETAILS),
        mergeMap(({ deliveryOrder, orderId }) =>
          this.store.pipe(
            select(fromAuth.selectSelectedBranch),
            first(),
            map((branch) => {
              const branchUid = branch.uid;
              return [deliveryOrder, orderId, branchUid];
            })
          )
        ),
        mergeMap(([deliveryOrder, orderId, branchUid, merchantOwnerUid]) =>
          this.fsService.setDeliveryDetails(deliveryOrder, orderId, branchUid).pipe(
            map(() => IntegrationActions.setDeliveryDetailsSuccess()),
            catchError((err) => of(IntegrationActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  removeDeliveryOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.REMOVE_DELIVERY_ORDER),
        mergeMap(({ orderId }) =>
          this.store.pipe(
            select(fromAuth.selectSelectedBranch),
            first(),
            map((branch) => {
              const branchUid = branch.uid;
              return [orderId, branchUid];
            })
          )
        ),
        mergeMap(([orderId, branchUid]) =>
          this.fsService.getOrderDetails(orderId, branchUid).pipe(
            map((orderDetails) => {
              return [branchUid, orderDetails];
            })
          )
        ),
        mergeMap(([branchUid, orderDetails]) =>
          this.rtdbService.removeOrder({ branchUid, orderDetails }).pipe(
            map(() => {
              return IntegrationActions.success();
            }),
            catchError((err) => of(IntegrationActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  updateOrderDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.UPDATE_ORDER_DETAILS),
        mergeMap(({ branchUid, orderDetails, deliveryOrder }) =>
          this.rtdbService.updateOrderDetails({ branchUid, orderDetails, deliveryOrder }).pipe(
            map(() => IntegrationActions.updateOrderDetailsSuccess()),
            catchError((err) => of(IntegrationActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  getDeliveryCourier$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.GET_DELIVERY_COURIER),
        mergeMap(({ platform, courierOrderId, merchantOwnerUid }) =>
          this.deliveryService.getCourier(platform, courierOrderId, merchantOwnerUid).pipe(
            map((courier) => {
              return IntegrationActions.setDeliveryCourier({ courier });
            }),
            catchError((err) => of(IntegrationActions.error({ error: err.message })))
          )
        )
      ),
    { dispatch: true }
  );

  constructor(
    private actions$: Actions,
    private deliveryService: DeliveryService,
    private rtdbService: RTDBService,
    private fsService: FirestoreService,
    private store: Store<StoreRootState>
  ) {}
}
