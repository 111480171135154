import { ApplicationRef } from '@angular/core';
// Core
import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

// Constants
import { REMOTE_CONFIG } from 'src/app/core/constants/remote.config.constants';

// Services
import { AuthService } from './services/firebase/auth.service';
import { RemoteConfigService } from 'src/app/services/firebase/remote.config.service';

// Stores
import { Store, select } from '@ngrx/store';
import { StoreRootState } from './state/state.reducers';
import * as AuthActions from './state/auth/auth.actions';
import * as AggregationActions from './state/aggregation/aggregation.actions';
import * as ContentActions from './state/content/content.actions';
import * as IntegrationActions from './state/integration/integration.actions';
import * as ManagementActions from './state/dashboard/management/management.actions';
import * as OperationActions from './state/dashboard/operation/operation.actions';
import * as ReportsActions from './state/reports/reports.actions';
import * as SettingsActions from './state/settings/settings.actions';
import * as StructureActions from './state/structure/structure.actions';

// Models
import { Merchant } from './models/data/merchant.model';
import { PageStructure } from 'src/app/models/structure/page.structure.model';
import { ContentStructure } from 'src/app/models/structure/content.structure.model';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public loading = true;

  constructor(
    public authService: AuthService,
    public translate: TranslateService,
    private store: Store<StoreRootState>,
    private remoteConfigService: RemoteConfigService,
    private app: ApplicationRef
  ) {
    this.initializeTranslate();
    this.initializeRemoteConfig();
    this.initializeTheme();
  }

  ngOnInit() {
    // this.dispatchUser();
    this.subscribeToUser();
  }

  initializeTheme() {
    this.authService.userDoc$.subscribe((userDoc: Merchant) => {
      if (userDoc) {
        const defaults = userDoc?.defaults;
        // this.changeThemeColor(defaults?.darkMode);
      }
      this.loading = false;
    });
  }

  initializeTranslate() {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  dispatchUser() {
    this.store.dispatch(AuthActions.getUser());
  }

  subscribeToUser() {
    this.getRemoteConfigs();
    this.authService.user$.subscribe((user) => {
      if (user) {
      } else {
        // this.resetState();
      }
      this.loading = false;
    });
  }

  resetState() {
    this.store.dispatch(AggregationActions.reset());
    this.store.dispatch(ContentActions.reset());
    this.store.dispatch(IntegrationActions.reset());
    this.store.dispatch(ManagementActions.reset());
    this.store.dispatch(OperationActions.reset());
    this.store.dispatch(ReportsActions.reset());
    this.store.dispatch(SettingsActions.reset());
    this.store.dispatch(StructureActions.reset());
    this.loading = false;
  }

  getRemoteConfigs() {
    this.remoteConfigService
      .fetchAndActivate()
      .then(() => {
        this.loading = true;
        // const structure: PageStructure = JSON.parse(
        //   this.remoteConfigService.getString(REMOTE_CONFIG.STRUCTURE.WEBMER_STRUCTURE)
        // );
        const content: ContentStructure = this.processRemoteContentStructure();

        // this.store.dispatch(StructureActions.setPageStructure({ structure }));
        this.store.dispatch(ContentActions.setContent({ content }));
      })
      .catch((err) => {
        // this.resetState();
        console.log('Remote Config Error', err);
      })
      .finally(() => {
        this.loading = false;
        this.app.tick();
      });
  }

  processRemoteContentStructure() {
    // const defaults = JSON.parse(this.remoteConfigService.getString(REMOTE_CONFIG.CONTENT.DEFAULT));
    // const dashboard = JSON.parse(this.remoteConfigService.getString(REMOTE_CONFIG.CONTENT.DASHBOARD));
    // const delivery = JSON.parse(this.remoteConfigService.getString(REMOTE_CONFIG.CONTENT.INTEGRATION_DELIVERY));
    // const order = JSON.parse(this.remoteConfigService.getString(REMOTE_CONFIG.CONTENT.INTEGRATION_ORDER));
    // const reports = JSON.parse(this.remoteConfigService.getString(REMOTE_CONFIG.CONTENT.REPORTS));
    // const settings = JSON.parse(this.remoteConfigService.getString(REMOTE_CONFIG.CONTENT.SETTINGS));
    // const translations = JSON.parse(this.remoteConfigService.getString(REMOTE_CONFIG.CONTENT.TRANSLATIONS));
    const tracking = JSON.parse(this.remoteConfigService.getString(REMOTE_CONFIG.CONTENT.TRACKING));
    const features = JSON.parse(this.remoteConfigService.getString(REMOTE_CONFIG.CONTENT.FEATURES));
    // const integration = {
    // delivery,
    // order,
    // };

    return {
      // defaults,
      // dashboard,
      // integration,
      // reports,
      // settings,
      // translations,
      tracking,
      features,
    };
  }

  initializeRemoteConfig() {
    this.remoteConfigService
      .ensureInitialized()
      .then(() => {
        console.log('Firebase Remote Config is initialized');
      })
      .catch((err) => {
        console.error('Firebase Remote Config failed to initialize', err);
      });
  }

  changeThemeColor(darkMode: boolean) {
    const body = document.getElementsByTagName('body')[0];
    const dark = 'dark-content';
    const light = 'white-content';
    if (darkMode) {
      body.classList.add(dark);
      body.classList.remove(light);
    } else {
      body.classList.add(light);
      body.classList.remove(dark);
    }
  }
}
