import { Injectable } from '@angular/core';

import { CloudFunctionsService } from 'src/app/services/firebase/cloud.functions.services';

import { OrderDetails } from 'src/app/models/data/order.model';
import { LalamoveQuote } from 'src/app/models/integration/lalamove.model';

import { INTEGRATION } from 'src/app/core/constants/integration.constants';

@Injectable({
  providedIn: 'root',
})
export class LalamoveService {
  private lalamoveFunctions = this.cloudFunction.get(INTEGRATION.COURIER.LALAMOVE);

  constructor(private cloudFunction: CloudFunctionsService) {}

  async quote(order: OrderDetails) {
    const data: LalamoveQuote = {
      serviceType: 'MOTORCYCLE',
      scheduleAt: '2018-06-13T12:00:00:00Z', // Note: This is in UTC Timezone
      specialRequests: [],
      requesterContact: {
        name: order.pickup.customer.name,
        phone: order.pickup.customer.mobile,
      },
      stops: [
        {
          location: {
            lat: order.pickup.address.lat,
            lng: order.pickup.address.lng,
          },
          addresses: {
            en_PH: {
              displayString: order.pickup.address.location,
              country: 'PH',
            },
          },
        },
        {
          location: {
            lat: order.delivery.address.lat,
            lng: order.delivery.address.lng,
          },
          addresses: {
            en_PH: {
              displayString: order.delivery.address.location,
              country: 'PH',
            },
          },
        },
      ],
      deliveries: [
        {
          toStop: 1,
          toContact: {
            name: order.delivery.customer.name,
            phone: order.delivery.customer.mobile,
          },
          remarks: 'ORDER #: 1234, ITEM 1 x 1, ITEM 2 x 2',
        },
      ],
    };
    return this.lalamoveFunctions.getQuote(data);
  }

  async order(order: OrderDetails) {
    const data: LalamoveQuote = {
      serviceType: 'MOTORCYCLE',
      scheduleAt: '2018-06-13T12:00:00:00Z', // Note: This is in UTC Timezone
      specialRequests: [],
      requesterContact: {
        name: order.pickup.customer.name,
        phone: order.pickup.customer.mobile,
      },
      stops: [
        {
          location: {
            lat: order.pickup.address.lat,
            lng: order.pickup.address.lng,
          },
          addresses: {
            en_PH: {
              displayString: order.pickup.address.location,
              country: 'PH',
            },
          },
        },
        {
          location: {
            lat: order.delivery.address.lat,
            lng: order.delivery.address.lng,
          },
          addresses: {
            en_PH: {
              displayString: order.delivery.address.location,
              country: 'PH',
            },
          },
        },
      ],
      deliveries: [
        {
          toStop: 1,
          toContact: {
            name: order.delivery.customer.name,
            phone: order.delivery.customer.mobile,
          },
          remarks: 'ORDER #: 1234, ITEM 1 x 1, ITEM 2 x 2',
        },
      ],
    };
    return this.lalamoveFunctions
      .createOrder({ data })
      .then((ord) => {
        return ord;
      })
      .catch((err) => {
        return err;
      });
  }

  async cancel(id: string) {
    return this.lalamoveFunctions.cancelOrder(id);
  }

  async getCourier(id: string) {
    return this.lalamoveFunctions.getCourier(id);
  }
}
