import { Action, ActionReducer, ActionReducerMap, combineReducers } from '@ngrx/store';
import { createReducer } from '@ngrx/store';
import * as managementReducer from './management/management.reducer';
import * as operationReducer from './operation/operation.reducer';

export interface State {
  management: managementReducer.State;
  operation: operationReducer.State;
}

export const initialState: State = {
  management: managementReducer.initialState,
  operation: operationReducer.initialState,
};

const reducers = {
  management: managementReducer.reducer,
  operation: operationReducer.reducer,
};

export const reducer: ActionReducer<State> = combineReducers(reducers);
