import { createAction, props } from '@ngrx/store';
import { PrioritiesByCategory } from 'src/app/models/data/priority.model';

export const GET_OVERDUE_ORDERS = '[Dashboard - Management] Get Overdue Orders';
export const GET_CRITICAL_ORDERS = '[Dashboard - Management] Get Critical Orders';
export const GET_URGENT_ORDERS = '[Dashboard - Management] Get Urgent Orders';
export const GET_NORMAL_ORDERS = '[Dashboard - Management] Get Normal Orders';

export const SET_OVERDUE_ORDERS = '[Dashboard - Management] Set Overdue Orders';
export const SET_CRITICAL_ORDERS = '[Dashboard - Management] Set Critical Orders';
export const SET_URGENT_ORDERS = '[Dashboard - Management] Set Urgent Orders';
export const SET_NORMAL_ORDERS = '[Dashboard - Management] Set Normal Orders';

export const GET_ORDERS_PRIORITIES = '[Dashboard - Management] Get Orders Priorities';
export const SET_ORDERS_PRIORITIES = '[Dashboard - Management] Set Orders Priorities';
export const INITIALIZE_ORDERS_PRIORITIES = '[Dashboard - Management] Initialize Orders Priorities';

// GET
export const GET_DAILY_PRIORITIES = '[Dashboard - Management] Get Daily Priorities';
export const GET_WEEKLY_PRIORITIES = '[Dashboard - Management] Get Weekly Priorities';
export const GET_MONTHLY_PRIORITIES = '[Dashboard - Management] Get Monthly Priorities';

// SET
export const SET_DAILY_PRIORITIES = '[Dashboard - Management] Set Daily Priorities';
export const SET_MONTHLY_PRIORITIES = '[Dashboard - Management] Set Monthly Priorities';
export const SET_WEEKLY_PRIORITIES = '[Dashboard - Management] Set Weekly Priorities';

export const GET_MONTHLY_PERFORMANCE = '[Dashboard - Management] Get Monthly Performance';
export const SET_MONTHLY_PERFORMANCE = '[Dashboard - Management] Set Monthly Performance';

export const SET_SELECTED_PRIORITY_FITLER = '[Dashboard - Management] Set Selected Priority Filter';

export const SET_SELECTED_DATE_FITLER = '[Dashboard - Management] Set Selected Date Filter';
export const SET_SELECTED_BRANCH = '[Dashboard - Management] Set Selected Branch';

export const RESET_MANAGEMENT = '[Content] Reset Management';

export const ERROR = '[Dashboard - Management] Error';

export const getOverdueOrders = createAction(GET_OVERDUE_ORDERS);
export const getCriticalOrders = createAction(GET_CRITICAL_ORDERS);
export const getUrgentOrders = createAction(GET_URGENT_ORDERS);
export const getNormalOrders = createAction(GET_NORMAL_ORDERS);

export const setOverdueOrders = createAction(SET_OVERDUE_ORDERS);
export const setCriticalOrders = createAction(SET_CRITICAL_ORDERS);
export const setUrgentOrders = createAction(SET_URGENT_ORDERS);
export const setNormalOrders = createAction(SET_NORMAL_ORDERS);

// GET
export const getDailyPriorities = createAction(GET_DAILY_PRIORITIES, props<{ year: string; month: string }>());
export const getWeeklyPriorities = createAction(GET_WEEKLY_PRIORITIES, props<{ year: string }>());
export const getMonthlyPriorities = createAction(GET_MONTHLY_PRIORITIES, props<{ year: string }>());

// SET
export const setDailyPriorities = createAction(SET_DAILY_PRIORITIES, props<{ daily: any }>());
export const setMonthlyPriorities = createAction(SET_MONTHLY_PRIORITIES, props<{ monthly: any }>());
export const setWeeklyPriorities = createAction(SET_WEEKLY_PRIORITIES, props<{ weekly: any }>());

export const getOrdersPriorities = createAction(GET_ORDERS_PRIORITIES);

export const setOrdersPriorities = createAction(SET_ORDERS_PRIORITIES, props<{ priorities: PrioritiesByCategory[] }>());

export const initializeOrdersPriorities = createAction(INITIALIZE_ORDERS_PRIORITIES);

export const setSelectedPriorityFilter = createAction(
  SET_SELECTED_PRIORITY_FITLER,
  props<{ selectedPriorityFilter: string }>()
);

export const setSelectedDateFilter = createAction(SET_SELECTED_DATE_FITLER, props<{ selectedDateFilter: string }>());

export const setSelectedBranch = createAction(SET_SELECTED_BRANCH, props<{ selectedBranch: string }>());

export const reset = createAction(RESET_MANAGEMENT);

export const error = createAction(ERROR, props<{ error: string }>());
