import { Injectable } from '@angular/core';
import { OrderDetails } from 'src/app/models/data/order.model';
import { CloudFunctionsService } from 'src/app/services/firebase/cloud.functions.services';
import { INTEGRATION } from 'src/app/core/constants/integration.constants';
import { from, of } from 'rxjs';
import { RTDBService } from '../firebase/rtdb.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ResportsService {
  constructor(private cloudFunction: CloudFunctionsService, private rtdb: RTDBService) {}

  getDailyReports(year, month, node) {
    return this.rtdb.getDailyReports(year, month, node);
  }

  getMonthlyReports(year, node) {
    return this.rtdb.getMonthlyReports(year, node);
  }

  getWeeklyReports(year, node) {
    return this.rtdb.getWeeklyReports(year, node);
  }
}
