import { createAction, props } from '@ngrx/store';

export const SET_PAGE_STRUCTURE = '[Settings] Get Page Structure';
export const GET_INTEGRATION_SETTINGS = '[Settings] Get Integration';
export const GET_FEATURE_SETTINGS = '[Settings] Get Feature';

export const RESET_SETTINGS = '[Structure] Reset Settings';

export const SUCCESS = '[Settings] Suc';
export const ERROR = '[Settings] Error';

export const getIntegrationSettings = createAction(GET_INTEGRATION_SETTINGS);
export const getFeatureSettings = createAction(GET_FEATURE_SETTINGS);

export const reset = createAction(RESET_SETTINGS);

export const error = createAction(ERROR, props<{ error: string }>());
export const success = createAction(SUCCESS);
