import { status } from 'src/app/models/data/task.model';
import { Injectable } from '@angular/core';
import { RTDB_CONSTANTS } from 'src/app/core/constants/rtdb.constants';

const MERCHANTS = RTDB_CONSTANTS.MERCHANTS;
const STATUS = RTDB_CONSTANTS.STATUS;
const STATUS_MAP = RTDB_CONSTANTS.STATUS_MAP;
const noOfStatuses = 3;

@Injectable({
  providedIn: 'root',
})
export class ProgressService {
  static statusProgressMap = {
    new: 1,
    inprogress: 2,
    finished: 3,
    removed: 0,
  };

  static themeStatusMap = {
    new: 'warning',
    inprogress: 'info',
    finished: 'success',
    removed: 'danger',
  };

  static mapToStatus(statu$: status) {
    return STATUS_MAP[statu$];
  }

  static computeStatusProgress(statu$: status) {
    const mapStatus = this.mapToStatus(statu$);
    return (this.statusProgressMap[mapStatus] / noOfStatuses) * 100;
  }

  static computeThemeStatusProgress(statu$: status) {
    const mapStatus = this.mapToStatus(statu$);
    return this.themeStatusMap[mapStatus];
  }
}
