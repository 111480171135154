import { map, catchError, tap, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of, from } from 'rxjs';

import { AuthService } from 'src/app/services/firebase/auth.service';
import { User } from './auth.model';

import * as AuthActions from './auth.actions';

@Injectable()
export class AuthEffects {
  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.GET_USER),
      mergeMap(() =>
        this.authService.user$.pipe(
          switchMap((auth) => this.authService.userDoc$),
          map((user: User) => {
            if (user) {
              return AuthActions.authenticated({ user });
            } else {
              return AuthActions.notAuthenticated();
            }
          }),
          catchError((error) => of(AuthActions.error({ error })))
        )
      )
    )
  );

  emailLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.EMAIL_LOGIN),
      mergeMap((action: any) =>
        from(this.authService.emailLogin(action.email, action.password)).pipe(
          map(() => AuthActions.getUser()),
          catchError((err) => of(AuthActions.error({ error: err.message })))
        )
      )
    )
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.REGISTER),
      mergeMap((action: any) =>
        from(this.authService.emailSignup(action.email, action.password)).pipe(
          map(() => AuthActions.getUser()),
          catchError((err) => of(AuthActions.error({ error: err.message })))
        )
      )
    )
  );

  googleConnect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.CONNECT_GOOGLE),
      mergeMap(() =>
        from(this.authService.googleConnect()).pipe(
          map(() => AuthActions.getUser()),
          catchError((err) => of(AuthActions.error({ error: err.message })))
        )
      )
    )
  );

  facebookConnect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.CONNECT_FACEBOOK),
      mergeMap(() =>
        from(this.authService.facebookConnect()).pipe(
          map(() => AuthActions.getUser()),
          catchError((err) => of(AuthActions.error({ error: err.message })))
        )
      )
    )
  );

  reset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.RESET_PASSWORD),
      mergeMap((action: any) =>
        from(this.authService.resetPassword(action.email)).pipe(
          map(() => AuthActions.getUser()),
          catchError((err) => of(AuthActions.error({ error: err.message })))
        )
      )
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LOGOUT),
        tap(() => this.authService.signOut()),
        catchError((err) => of(AuthActions.error({ error: err.message })))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private authService: AuthService) {}
}
