import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

const defaults = {
  timeOut: 8000,
  closeButton: false,
  enableHtml: true,
  positionClass: 'toast-top-right',
};

export type TOAST_TYPE = 'default' | 'info' | 'success' | 'warning' | 'danger';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastr: ToastrService) {}

  showToast(msg: string, type: TOAST_TYPE) {
    this.toastr.show('<span class="tim-icons icon-bell-55"></span>', msg, {
      ...defaults,
      toastClass: `alert alert-${type} alert-with-icon`,
    });
  }

  showDefault(msg: string) {
    this.showToast(msg, 'default');
  }

  showInfo(msg: string) {
    this.showToast(msg, 'info');
  }

  showSuccess(msg: string) {
    this.showToast(msg, 'success');
  }

  showWarning(msg: string) {
    this.showToast(msg, 'warning');
  }

  showDanger(msg: string) {
    this.showToast(msg, 'danger');
  }
}
