import { timeZone } from 'src/app/core/types/timezone.type';
import * as moment from 'moment-timezone';

export const defaultLang = 'en-US';
export const defaultFormat = 'YYYY/M/D';

export const moment$ = moment;

const setLocaleAndTz = (timezone: timeZone, language: string = defaultLang) => {
  moment.locale(language);
  moment.tz.setDefault(timezone);
};

export const getClientTimezone = () => {
  return moment.tz.guess() as timeZone;
};

export const getDateWithClientTz = (date?: moment.Moment, language: string = defaultLang) => {
  setLocaleAndTz(getClientTimezone(), language);
  const today = date ? moment(date) : moment();
  return moment(today);
};

export const getDateinTimeZone = (date: any, timezone: timeZone, language: string = defaultLang) => {
  setLocaleAndTz(timezone, language);
  return date ? moment(date) : moment();
};

export const getTimein24HrFormat = (date: string, timezone: timeZone, language: string = defaultLang) => {
  const dateInTimeZone = getDateinTimeZone(date, timezone, language);
  return moment(dateInTimeZone).format('hh:mm a');
};

export const getDateInLongFormat = (date: string, timezone: timeZone, language: string = defaultLang) => {
  const dateInTimeZone = getDateinTimeZone(date, timezone, language);
  return moment(dateInTimeZone).format('MMMM Do YYYY, h:mm a');
};

export const getTodayInShortFormat = () => {
  return moment(getDateWithClientTz(moment())).format(defaultFormat);
};

export const getYesterdayInShortFormat = () => {
  const yesterday = moment().subtract(1, 'd').date();
  return moment(getDateWithClientTz(moment(yesterday, 'DD'))).format(defaultFormat);
};

export const getDateInShortFormat = (date: moment.Moment) => {
  return moment(getDateWithClientTz(moment(date))).format(defaultFormat);
};

export const convertFromDateString = (date: Date) => {
  return getDateWithClientTz(moment(date)).format('dddd MMMM Do YYYY HH:mm:ss Z');
};
