import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { RTDB_CONSTANTS } from 'src/app/core/constants/rtdb.constants';
import { RealTimeOrder, OrderDetails } from 'src/app/models/data/order.model';
import { OrderAggregation } from 'src/app/models/aggregation/order.aggregation.model';

import { RTDBService } from 'src/app/services/firebase/rtdb.service';
import { FirestoreService } from 'src/app/services/firebase/firestore.service';

import * as OperationActions from './operation.actions';
import * as IntegrationActions from 'src/app/state/integration/integration.actions';

const MERCHANTS = RTDB_CONSTANTS.MERCHANTS;

@Injectable()
export class OperationEffects {
  getRealTimeOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationActions.GET_REALTIME_ORDERS),
      mergeMap(({ selectedBranch, dateFilter }) =>
        this.rtdbService.listenToRealTimeOrders(selectedBranch, dateFilter).pipe(
          map((orders: RealTimeOrder[]) => {
            return OperationActions.setRealTimeOrders({ orders });
          }),
          catchError((err) => of(OperationActions.error({ error: err.message })))
        )
      )
    )
  );

  getOrderDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationActions.GET_ORDER_DETAILS),
      mergeMap(({ id }) =>
        this.fsService.getOrderDetails(id, '').pipe(
          switchMap((orderDetails: OrderDetails) => [OperationActions.setOrderDetails({ orderDetails })]),
          catchError((err) => of(OperationActions.error({ error: err.message })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private rtdbService: RTDBService, private fsService: FirestoreService) {}
}
