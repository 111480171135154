import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

if (environment.production) {
  enableProdMode();
}

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/performance';
import 'firebase/analytics';
import 'firebase/remote-config';
import 'firebase/database';

firebase.initializeApp(environment.config);
firebase.performance();
firebase.analytics();
firebase.remoteConfig();
firebase.database();

// Sentry.init({
//   dsn: 'https://4e8b3fbafa6e4c929b24dd3e4bd2c3b9@o447300.ingest.sentry.io/5427024',
//   integrations: [
//     new Integrations.BrowserTracing({
//       tracingOrigins: ['localhost', 'https://merchant.zipeli.com'],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
//   tracesSampleRate: 1.0,
// });

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
