import { createSelector } from '@ngrx/store';
import { Tracking } from 'src/app/models/v2/tracking.model';
import { StoreRootState } from 'src/app/state/state.reducers';

export const selectTracker = (state: StoreRootState) => state?.tracker;

export const selectTask = createSelector(selectTracker, (tracker: any) => tracker.task);

export const selectTaskStatus = createSelector(selectTask, (trackingData: Tracking) => trackingData.s);

export const selectRating = createSelector(selectTracker, (tracker: any) => tracker.rating);

export const selectRatingResponse = createSelector(selectTracker, (tracker: any) => tracker.ratingResponse);

export const selectError = createSelector(selectTracker, (tracker: any) => tracker.error);
