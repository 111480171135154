declare const google: any;

import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import swal from 'sweetalert2';
import * as _ from 'lodash';

// MODELS
import { AgentTracking } from 'src/app/models/v2/agent.model';
import { CustomerRTDB } from 'src/app/models/v2/customer.model';
import { Tracking } from 'src/app/models/v2/tracking.model';

// State
import { Store } from '@ngrx/store';
import { StoreRootState } from 'src/app/state/state.reducers';
import * as TrackerActions from 'src/app/state/tracker/tracker.actions';
import * as fromTracker from 'src/app/state/tracker/tracker.selector';
import * as fromContent from 'src/app/state/content/content.selector';
import * as fromRoute from 'src/app/state/routes/selectors';
import { fromEvent, merge, Observable, Observer } from 'rxjs';
import { NotificationService, TOAST_TYPE } from 'src/app/services/utils/notification.service';
import { map } from 'rxjs/operators';
import { Rating } from '../../models/v2/rating.model';

// Constants
import { ORDER_STATUS } from '@constants/global.constants';

@UntilDestroy({
  checkProperties: true,
})
@Component({
  selector: 'app-tracker',
  templateUrl: 'tracker.component.html',
  styleUrls: ['tracker.component.scss'],
})
export class TrackerComponent implements OnInit {
  @ViewChild('ratingModal') ratingModal;
  // forms
  focus = {
    rating: false,
    comment: false,
  };

  agentNumber: string;
  map: any;
  riderMarker: any;
  customerMarker: any;
  merchantMarker: any;
  customer: CustomerRTDB;
  agent: AgentTracking;
  customerLocationMarker: any;
  zoomToRider = true;
  markers = [];
  collapseOne = true;
  collapseThree = true;
  collapseTwo = true;
  customerCurrentLocation: any;
  trackingId: string;
  customerLocationId = 'customerLocation';
  public trackingTask$: Observable<any>;
  public ratingResponse$: Observable<any>;
  public error$: Observable<any>;
  public delivered = false;
  ratingModal$ = null;
  ratingSubmitted = false;
  msg: string;
  public mapInitialize = false;

  ratingForm: FormGroup;
  texts: any;
  trackingContent$: Observable<any>;
  deliveryNotification: string[];
  statusColors: TOAST_TYPE[];
  statusLabel: string;
  statusColor: TOAST_TYPE;
  public status: number;

  avatar = 'https://storage.googleapis.com/zipub/v2/generic-avatar.png';

  constructor(
    private translate: TranslateService,
    private store: Store<StoreRootState>,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private notification: NotificationService
  ) {
    this.ratingForm = this.fb.group({
      rating: ['', Validators.required],
      comment: [''],
    });
  }

  ngOnInit() {
    this.getDataFromRoute();
    this.initializeTrackerTranslation();
    this.initializeRatingData();
    this.listenToTrackingContent();
    this.listenToTasksStatus();
    this.subscribeToNetworkChecker();
  }

  // check network connection
  checkNetwork$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }

  subscribeToNetworkChecker() {
    this.checkNetwork$().subscribe((isOnline: boolean) => {
      if (isOnline) {
        this.notification.showSuccess(this.texts?.network?.connected);
      } else {
        this.displayDisconnectedNetworkMessage();
      }
    });
  }

  get rating() {
    return this.ratingForm.get('rating');
  }

  get comment() {
    return this.ratingForm.get('comment');
  }

  initializeRatingData() {
    this.error$ = this.store.select(fromTracker?.selectError);
    this.ratingResponse$ = this.store.select(fromTracker?.selectRatingResponse);
    this.ratingResponse$.subscribe((ratingResponse: Rating) => {
      if (ratingResponse?.agent?.rating) {
        if (this.ratingSubmitted) {
          this.displaySuccessMessage();
          this.ratingModal$ = this.ratingModal && this.ratingModal.hide();
          this.ratingSubmitted = false;
        } else {
          this.rating.setValue(ratingResponse?.agent?.rating);
          this.comment.setValue(ratingResponse?.agent?.comment);
        }
      }
    });
    this.error$.subscribe((error: any) => {
      if (error) {
        this.displayErrorMessage(error.message);
      }
    });
  }

  initializeTrackerTranslation() {
    this.translate.get('TRACKER').subscribe((texts) => {
      this.texts = texts;
    });
  }

  displaySuccessMessage() {
    swal.fire({
      title: this.texts?.modal?.success?.title,
      text: this.texts?.modal?.success?.message,
      type: 'success',
      confirmButtonClass: 'btn btn-success',
      buttonsStyling: false,
    });
  }

  displayErrorMessage(message: string) {
    swal.fire({
      title: this.texts?.modal?.error?.title,
      text: message,
      type: 'error',
      confirmButtonClass: 'btn btn-success',
      buttonsStyling: false,
    });
  }

  displayDisconnectedNetworkMessage() {
    swal.fire({
      title: this.texts?.network?.disconnected?.title,
      text: this.texts?.network?.disconnected?.message,
      type: 'error',
      confirmButtonClass: 'btn btn-danger',
      buttonsStyling: false,
    });
  }

  clearMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
    this.markers = [];
  }

  deleteMarker(id: string) {
    for (let i = 0; i < this.markers.length; i++) {
      if (this.markers[i].get('id') === id) {
        this.markers[i].setMap(null);
      }
    }
  }

  getDataFromRoute() {
    this.store.select(fromRoute.getCurrentRouteState).subscribe((route: any) => {
      this.trackingId = route.queryParams.id;
      this.store.dispatch(
        TrackerActions.getTask({
          taskId: `${route.queryParams.id}`,
        })
      );
      this.store.dispatch(
        TrackerActions.getRating({
          taskId: this.trackingId,
        })
      );
    });
  }

  setRating(rating: Rating) {
    this.store.dispatch(
      TrackerActions.setRating({
        rating,
      })
    );
  }

  submitRating() {
    const rating: Rating = {
      agent: {
        name: this.agent?.n,
        id: this.agent?.id,
        contact: this.agent?.c,
        rating: this.ratingForm?.value?.rating ?? '',
        comment: this.ratingForm?.value?.comment ?? '',
      },
      customer: {
        name: this.customer?.n,
        id: this.customer?.id,
        contact: this.customer?.c,
        comment: this.customer?.ratingForm?.value?.comment ?? '',
      },
      orderId: this.trackingId,
      tz: this.customer?.tz,
    };
    this.setRating(rating);
    this.ratingSubmitted = true;
  }

  listenToTasksStatus() {
    this.store.select(fromTracker.selectTaskStatus).subscribe((status: number) => {
      if (typeof status === 'undefined') {
        return;
      }
      this.orderSuccess(status);
      this.statusLabel = this.getDeliveryNotification(status);
      this.statusColor = this.getStatusColor(status);
      if (status === 5) {
        this.ratingModal$ = this.ratingModal && this.ratingModal.show();
        this.delivered = true;
      } else {
        this.ratingModal$ = this.ratingModal && this.ratingModal.hide();
        this.notification.showToast(this.statusLabel, this.statusColor);
        this.delivered = false;
      }
    });
  }

  getStatusColor(status: number) {
    return this.statusColors[status];
  }

  getDeliveryNotification(status: number) {
    return this.deliveryNotification?.length > 0
      ? this.deliveryNotification[status]
      : this.texts?.statusNotification[status];
  }

  listenToTrackingContent() {
    this.trackingContent$ = this.store.select(fromContent.selectContentTracking);
    this.trackingContent$.subscribe((content: any) => {
      this.deliveryNotification = content?.delivery?.notification;
      this.statusColors = content?.delivery?.notificationColor;
    });
  }

  sanitize(url: string): any {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  initializeCustomerData() {
    this.trackingTask$ = this.store.select(fromTracker.selectTask);
    this.trackingTask$.subscribe((tracking: Tracking) => {
      if (tracking) {
        this.customer = tracking?.c;
        this.agent = tracking?.a;
        this.clearMarkers();
        const customerLocation = this.customer?.l;
        if (!_.isEmpty(customerLocation)) {
          const cPos = {
            lat: Number(customerLocation[0]),
            lng: Number(customerLocation[1]),
          };
          if (cPos.lat && cPos.lng) {
            this.customerMarker = this.addMarker(
              cPos,
              'https://storage.googleapis.com/zipub/v2/marker-customer.png',
              'customer'
            );
          } else {
            this.notification.showDanger(this.texts?.location?.errorLocation?.title);
          }
        }
        const agentLocation = tracking?.a?.l;
        if (!_.isEmpty(agentLocation)) {
          const rPos = {
            lat: Number(agentLocation[0]),
            lng: Number(agentLocation[1]),
          };
          if (rPos.lat && rPos.lng) {
            this.riderMarker = this.addMarker(
              rPos,
              'https://storage.googleapis.com/zipub/v2/marker-agent.png',
              'rider'
            );
          }
        }
        const merchantLocation = tracking?.m?.l;
        if (!_.isEmpty(merchantLocation)) {
          const mPos = {
            lat: Number(merchantLocation[0]),
            lng: Number(merchantLocation[1]),
          };
          if (mPos.lat && mPos.lng) {
            this.merchantMarker = this.addMarker(
              mPos,
              'https://storage.googleapis.com/zipub/v2/marker-merchant.png',
              'merchant'
            );
          }
        }
        this.setZoomToAllMarkers();
      }
    });
  }

  createCustomZoomControl(controlDiv: any) {
    const zoomToRiderIcon = 'https://storage.googleapis.com/zipub/zoom-to-rider.png';
    const zoomToAllIcons = 'https://storage.googleapis.com/zipub/zoom-to-all.png';
    const zoomToRiderTitle = 'Zoom to Rider';
    const zoomToAllTitle = 'Zoom to All Markers';
    // Set CSS for the control border.
    const controlUI = document.createElement('div');
    controlUI.style.cursor = 'pointer';
    controlUI.title = zoomToAllTitle;
    controlDiv.appendChild(controlUI);
    // Set CSS for theage control interior.
    const controlImg = document.createElement('img');
    controlImg.src = zoomToRiderIcon;
    controlImg.style.width = '90%';
    controlUI.appendChild(controlImg);
    // Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener('click', () => {
      if (this.zoomToRider) {
        this.setZoomToRider();
        controlImg.src = zoomToAllIcons;
        controlUI.title = zoomToAllTitle;
      } else {
        this.setZoomToAllMarkers();
        controlImg.src = zoomToRiderIcon;
        controlUI.title = zoomToRiderTitle;
      }
      this.zoomToRider = !this.zoomToRider;
    });
  }

  initializeGoogleMap() {
    this.map = document.getElementById('map');

    const lat = 14.577936;
    const lng = 121.008389;

    const latlng = new google.maps.LatLng(lat, lng);
    const mapOptions = {
      zoom: 16,
      scrollwheel: false,
      center: latlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.TOP_CENTER,
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER,
      },
      scaleControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.LEFT_TOP,
      },
      fullscreenControl: true,
    };

    this.map = new google.maps.Map(this.map, mapOptions);
    const centerControlDiv = document.createElement('div');
    this.createCustomZoomControl(centerControlDiv);
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(centerControlDiv);
  }

  addMarker(latlng: any, url: string, markerId: string) {
    const icon = {
      url,
      ...this.getMarkerSize(),
    };
    const marker = new google.maps.Marker({
      map: this.map,
      draggable: false,
      position: latlng,
      icon,
      id: markerId,
    });
    this.markers.push(marker);
    return marker;
  }

  getMarkerSize() {
    return {
      scaledSize: new google.maps.Size(60, 60),
    };
  }

  setZoomToAllMarkers() {
    const bounds = new google.maps.LatLngBounds();
    if (this.markers.length > 0) {
      for (let i = 0; i < this.markers.length; i++) {
        bounds.extend(this.markers[i].getPosition());
      }
      this.map.fitBounds(bounds);
    }
    this.getCurrentLocation();
  }

  setZoomToRider() {
    const latlng = this.riderMarker.getPosition();
    if (!_.isEmpty(latlng)) {
      this.map.setZoom(20);
      this.map.setCenter(latlng);
      this.map.panTo(this.riderMarker.position);
    }
  }

  getCurrentLocation() {
    if (!navigator.geolocation) {
      console.log('location is not supported');
    }
    this.watchPosition();
  }

  watchPosition() {
    navigator.geolocation.watchPosition(
      (position) => {
        const cPos = {
          lat: Number(position.coords.latitude),
          lng: Number(position.coords.longitude),
        };
        this.deleteMarker(this.customerLocationId);
      },
      (err) => {
        console.log(err);
      },
      {
        enableHighAccuracy: true, // this will consume more power in user's phone
        timeout: 5000,
        maximumAge: 0,
      }
    );
  }

  orderSuccess(status: number) {
    if (status === ORDER_STATUS.SUCCESSFUL || status === ORDER_STATUS.UNASSIGN) {
      this.mapInitialize = !this.mapInitialize;
    } else {
      this.status = ORDER_STATUS.SUCCESSFUL;
      this.mapInitialize = true;
      this.initializeGoogleMap();
      this.initializeCustomerData();
    }
  }
}
