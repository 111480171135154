// Cloud Functions
const COURIER = {
  MRSPEEDY: 'mrspeedy',
  LALAMOVE: 'lalamove',
};
const BASE = {
  MRSPEEDY: 'merchant-integration-delivery-mrSpeedy',
  LALAMOVE: 'merchant-integration-delivery-lalamove',
  PAYMONGO: 'payment-paymongo-createPaymentMethod',
};
const METHODS = {
  GETQUOTE: '-getQuote',
  CREATEORDER: '-createOrder',
  CANCELORDER: '-cancelOrder',
  GETCOURIER: '-getCourier',
};
const ORDER = {};
const DELIVERY = {
  mrspeedy: {
    GET_QUOTE: `merchantMrSpeedyIntegGetQuote`,
    CREATE_ORDER: `merchantMrSpeedyIntegCreateOrder`,
    CANCEL_ORDER: `merchantMrSpeedyIntegCancelOrder`,
    GET_COURIER: `merchantMrSpeedyIntegGetCourier`,
  },
  lalamove: {
    GET_QUOTE: `${BASE.LALAMOVE}${METHODS.GETQUOTE}`,
    CREATE_ORDER: `${BASE.LALAMOVE}${METHODS.CREATEORDER}`,
    CANCEL_ORDER: `${BASE.LALAMOVE}${METHODS.CANCELORDER}`,
    GET_COURIER: `${BASE.LALAMOVE}${METHODS.GETCOURIER}`,
  },
};

const PAYMONGO = {
  CREATE_PAYMENT_METHOD: `${BASE.PAYMONGO}`,
};

export const INTEGRATION = {
  ORDER,
  DELIVERY,
  PAYMONGO,
  COURIER,
};
