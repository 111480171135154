import { Integration } from 'src/app/models/integration/integration.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as IntegrationActions from './integration.actions';

export interface State extends Integration {
  error: Error;
}

const initialDeliveryPricing = {
  mrspeedy: {
    loading: true,
  },
  lalamove: {
    loading: true,
  },
  transportify: {
    loading: true,
  },
  grab: {
    loading: true,
  },
  angkas: {
    loading: true,
  },
  zip: {
    loading: true,
  },
};

export const initialState: State = {
  delivery: {
    selected: null,
    pricing: { ...initialDeliveryPricing },
    assigned: {},
  },
  order: null,
  payment: null,
  error: null,
};

const integrationReducer = createReducer(
  initialState,
  on(IntegrationActions.initializeDeliveryPricing, (state) => ({
    ...state,
    delivery: {
      ...state.delivery,
      pricing: initialDeliveryPricing,
    },
  })),
  on(IntegrationActions.cancelDeliveryOrder, (state) => state),
  on(IntegrationActions.removeDeliveryOrder, (state) => state),
  on(IntegrationActions.getDeliveryCourier, (state) => state),
  on(IntegrationActions.setDeliveryCourier, (state, { courier }) => ({
    ...state,
    delivery: {
      ...state.delivery,
      pricing: {
        ...state.delivery.pricing,
        ...courier,
      },
    },
    error: null,
  })),
  on(IntegrationActions.getDeliveryPricing, (state) => state),
  on(IntegrationActions.setDeliveryPricing, (state, { pricing }) => ({
    ...state,
    delivery: {
      ...state.delivery,
      pricing: {
        ...state.delivery.pricing,
        ...pricing,
      },
    },
    error: null,
  })),
  on(IntegrationActions.setSelectedDeliveryPlatform, (state, { platform }) => ({
    ...state,
    delivery: {
      ...state.delivery,
      selected: platform,
    },
  })),
  on(IntegrationActions.assignDeliveryPlatform, (state) => state),
  on(IntegrationActions.setDeliveryDetails, (state, { deliveryOrder, orderId }) => ({
    ...state,
    delivery: {
      ...state.delivery,
      assigned: {
        ...state.delivery.assigned,
        [orderId]: deliveryOrder,
      },
    },
  })),
  on(IntegrationActions.updateOrderDetails, (state) => state),
  on(IntegrationActions.reset, () => ({ ...initialState })),
  on(IntegrationActions.success, (state) => state),
  on(IntegrationActions.error, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return integrationReducer(state, action);
}
